import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Button, Typography } from 'antd';

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography; 

const Welcome = () => {
  return (
    <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Content className="flex flex-col justify-center items-center py-8" style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img 
            src="/logo-blue.svg" 
            alt="Logo" 
            style={{ width: '150px', height: 'auto', marginBottom: '10px' }} 
          /> 
          <Title level={3}>Secure Your Data</Title>
        <div className="text-center p-5" style={{ maxWidth: '400px' }}> 
          <Title level={2} className="mb-3">The AI compliance engine and quality management system</Title>
          <Paragraph className="mb-5">
            {/* Add a short descriptive paragraph here */}  
          </Paragraph>
          <Link to="/login">
            <Button type="primary btn-primary" size="large">Get Started</Button>
          </Link>
        </div>
      </Content>
     </div>
  );
};

export default Welcome;
