import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite"; // For 'lite' version
// import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAdbWU01D-oaq9D7SjFKUNDFOtm7NsucS4",
    authDomain: "scalesafe-firebase.firebaseapp.com",
    projectId: "scalesafe-firebase",
    storageBucket: "scalesafe-firebase.appspot.com",
    messagingSenderId: "628910406481",
    appId: "1:628910406481:web:465c290afa0f8abf707170",
    measurementId: "G-TEL6KXNMFM"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);