import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore/lite'; // Using 'lite' version for smaller bundle size
import { Table, Spin, Space, Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { render } from '@testing-library/react';


const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name) => (
        <div className='items-center justify-center'>
          <a href={`/models/${name}`}>{name}</a>
        </div>
      ),
    },
    {
      title: 'Assessment ID',
      dataIndex: 'assessmentId',
      key: 'assessmentId',
    },
    {
      title: 'Created Date',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: 'Last Used',
      dataIndex: 'latestUse',
      key: 'latestUse',
    },
    {
      title: 'API Keys',
      dataIndex: 'apiKeyCount',
      key: 'apiKeyCount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <div className='items-center justify-center'>
        <span>
          <span style={{ 
            height: '10px', 
            width: '10px', 
            backgroundColor: status ? 'green' : 'red', 
            borderRadius: '50%', 
            display: 'inline-block',
          }}></span>
        </span>
        </div>
      ),
    },
];



const getModelKeyStats = async (userUID) => {
    try {
      const keysCollectionRef = collection(db, `/users/${userUID}/keys`);
      const snapshot = await getDocs(keysCollectionRef);
      const stats = {};
  
      snapshot.forEach(doc => {
        const data = doc.data();
        const { modelId, lastUsed, monitor_calls } = data;
  
        if (!stats[modelId]) {
          stats[modelId] = {
            apiKeyCount: 0,
            lastUsed,
            totalMonitorCalls: 0
          };
        }
  
        stats[modelId].apiKeyCount += 1;
        stats[modelId].totalMonitorCalls += monitor_calls;
  
        // Update lastUsed if the current doc is more recent
        if (new Date(lastUsed) > new Date(stats[modelId].lastUsed)) {
          stats[modelId].lastUsed = lastUsed;
        }
      });
  
      return stats;
    } catch (error) {
      console.error("Error fetching keys: ", error);
    }
};



const ModelsList = ( { setNewModelModal }) => {
    const [models, setModels] = useState([]);
    const [modelTable, setModelTable] = useState([]); 
    const [isLoading, setIsLoading] = useState(false);


    const navigate = useNavigate();
  
    const userUID = useSelector((state) => state.auth.userId);

    useEffect(() => {
        const fetchModels = async () => { 
            setIsLoading(true); 
            try {
                if (userUID) {
                    const modelsCollectionRef = collection(db, `/users/${userUID}/models`);
                    const data = await getDocs(modelsCollectionRef);
                    // Initialize an array to hold promises for API keys count
                    let modelsData = data.docs.map(doc => {
                        const modelData = doc.data();
                        modelData.created = modelData.created?.toDate().toLocaleString(); // Convert the timestamp
                        return { ...modelData, id: doc.id };
                    });

                    getModelKeyStats(userUID).then(
                        (stats) => {
                            modelsData = modelsData.map((model) => {
                                const statsForModel = stats[model.id];
                                if (!statsForModel) {
                                    return {
                                        ...model,
                                        apiKeyCount: 0,
                                        latestUse: "N/A",
                                        status: model.status !== undefined ? model.status : false // Assuming status is boolean
                                    };
                                } else {
                                    return {
                                        ...model,
                                        apiKeyCount: statsForModel.apiKeyCount || 0,
                                        latestUse: statsForModel.lastUsed ? statsForModel.lastUsed.toDate().toLocaleString() : "N/A",
                                        status: model.status !== undefined ? model.status : false // Assuming status is boolean
                                    };
                                }
                            });
                            setModels(modelsData);
                        }
                    );
                    
                } else {
                    // Handle if userUID is not yet available
                } 
            } catch (error) {
                console.log("Error fetching models:", error);
                // Handle the error gracefully
            } finally {
                setIsLoading(false);
            }
        };
        
        fetchModels();
    }, [userUID]);

    useEffect(() => {
        try {
            console.log("Models: ", models);
            setModelTable(models.map((model) => ({
                key: model.id,
                name: model.name,
                assessmentId: model.assessmentId,
                created: model.created,
                apiKeyCount: model.apiKeyCount,
                latestUse: model.latestUse,
                status: model.status,
            })));
        } catch (error) {
            console.error("Error creating model table:", error);
            // Display an error message to the user (see Step 3)
        }
    }, [models]);

    
    return (
        <div>
            {isLoading ? (
                <Space size="middle" className="my-5"> 
                    <Spin size="large" />
                </Space>
            ) : (
                <div> 
                    {models.length === 0 ? (
                    <div className="mt-4"> 
                        <Typography.Paragraph>You don't have any models yet.</Typography.Paragraph>
                        <Button type="primary btn-primary" target="_blank" onClick={() => setNewModelModal(true)}>
                            Create a new model on ScaleSafe
                        </Button>
                    </div>
                    ) : (
                    <div>
                        <Table 
                            dataSource={modelTable} 
                            columns={columns} 
                            onRow={(record) => ({ onClick: () => navigate(`/models/${record.name}`) })}
                        /> 
                        <div className="mt-1 items-center justify-center">
                            <Button type="primary btn-primary" onClick={() => setNewModelModal(true)}>
                                Create a new model
                            </Button>
                        </div>
                    </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ModelsList;


export const MiniModelList = () => {
    // This component will display a mini list of model names and link to them.
    const [models, setModels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const userUID = useSelector((state) => state.auth.userId);

    useEffect(() => {
        const fetchModels = async () => { 
            setIsLoading(true); 
            try {
                if (userUID) {
                    const modelsCollectionRef = collection(db, `/users/${userUID}/models`);
                    const data = await getDocs(modelsCollectionRef);
                    let modelsData = data.docs.map(doc => {
                        return { ...doc.data(), id: doc.id };
                    });
                    setModels(modelsData);
                } else {
                    // Handle if userUID is not yet available
                } 
            } catch (error) {
                console.log("Error fetching models:", error);
                // Handle the error gracefully
            } finally {
                setIsLoading(false);
            }
        };
        
        fetchModels();
    }, [userUID]);

    return (
        <div>
            {isLoading ? (
                <Space size="middle" className="my-5"> 
                    <Spin size="large" />
                </Space>
            ) : (
                <div> 
                    {models.length === 0 ? (
                    <div className="mt-4"> 
                        <Typography.Paragraph>You don't have any models yet.</Typography.Paragraph>
                    </div>
                    ) : (
                    <div>
                        <Table 
                            dataSource={modelTable}
                            columns={[{title: 'Models', dataIndex: 'name', key: 'name',}]}  
                            onRow={(record) => ({ onClick: () => navigate(`/models/${record.name}`) })}
                        />
                    </div>
                    )}
                </div>
            )}
        </div>
    );
};