import { doc, getDoc } from 'firebase/firestore/lite'; 
import { db } from '../../firebase'; // Ensure db is properly configured in your firebase settings

/**
 * Fetches user data from Firestore based on the provided userId.
 * 
 * @param {string} userId The unique identifier for the user.
 * @returns {Promise<object>} A promise that resolves to the user data object.
 */
const fetchUserData = async (userId) => {
    try {
        const userDoc = doc(db, "users", userId); // Reference to the user's document in the 'users' collection
        const docSnap = await getDoc(userDoc);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            return docSnap.data(); // Return the data as an object
        } else {
            // No such document found
            console.error("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error fetching document:", error);
        throw error; // Re-throw to handle it where the function is called
    }
};

export default fetchUserData;
