export const retryWithExponentialBackoff = async (operation, attempts, delay) => {
    try {
      // Attempt the operation
      return await operation();
    } catch (error) {
      if (attempts <= 1) throw error; // No more attempts left
  
      // Wait for a bit before retrying
      await new Promise((resolve) => setTimeout(resolve, delay));
  
      // Retry the operation with an increased delay
      return retryWithExponentialBackoff(operation, attempts - 1, delay * 2);
    }
  };
  