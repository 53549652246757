// export type SiteConfig = typeof siteConfig; # for future typing

export const siteConfig = {
	name: "ScaleSafe",
	description: "Developer friendly, globally scalable, compliance for AI",
	pages: {
		welcome: {
			label: "Welcome",
			path: "/",
			route: () => "/",
		},
		assessment: {
			label: "Assessment",
			path: "/assessment",
			route: () => "/assessment",
		},
		login: {
			label: "Login",
			path: "/login",
			route: () => "/login",
		},
		home: {
			label: "Dashboard",
			path: "/dashboard",
			route: () => "/dashboard",
		},
		modelDashboard: {
			label: "Model Dashboard",
			path: "/models/:modelId",
			route: (modelId) => `/models/${modelId}`,
		},
		obligations: {
			label: "Obligations",
			path: "/models/:modelId/obligations",
			route: (modelId) => `/models/${modelId}/obligations`,
		},
		benchmarking: {
			label: "Benchmarking",
			path: "/models/:modelId/benchmarking",
			route: (modelId) => `/models/${modelId}/benchmarking`,
		},
		paperwork: {
			label: "Paperwork",
			path: "/models/:modelId/paperwork",
			route: (modelId) => `/models/${modelId}/paperwork`,
		},
		monitoring: {
			label: "Monitoring",
			path: "/models/:modelId/monitoring",
			route: (modelId) => `/models/${modelId}/monitoring`,
		},
		apiKeys: {
			label: "API Keys",
			path: "/models/:modelId/api-keys",
			route: (modelId) => `/models/${modelId}/api-keys`,
		},
		eventReview: {
			label: "Event Review",
			path: "/models/:modelId/eventreview",
			route: (modelId) => `/models/${modelId}/eventreview`,
		},
		screeningPolicy: {
			label: "Screening Policy",
			path: "/models/:modelId/screeningpolicy",
			route: (modelId) => `/models/${modelId}/screeningpolicy`,
		},
		audit: {
			label: "Generate Audit",
			path: "/models/:modelId/audit",
			route: (modelId) => `/models/${modelId}/audit`,
		},
	},
	externalLinks: {
		github: "https://github.com/ScaleSafe/scalesafe",
        assess: "https://scalesafe.ai/assess",
	},
	api: {
		// get_conformity_assessment_url: "http://127.0.0.1:5001/scalesafe-firebase/us-central1/get_conformity_assessment",
		get_conformity_assessment_url: 'https://api.scalesafe.ai/v1/get_conformity_assessment',
	},
};