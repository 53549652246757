import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModelData } from '../features/models/modelSlice';
import { useParams, useContext, useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore/lite'; 
import { db, auth } from '../firebase';
import { Link } from 'react-router-dom';
import { Layout, Typography, Spin, List, Card, Button, Row, Col, Tabs, Breadcrumb, Steps, Flex} from 'antd';
import APIKeys from './APIKeys';
import MonitoredEvents from '../components/MonitoredEvents';
import { getRequiredSteps } from '../features/models/modelJotLogic';
import {
    PieChartOutlined,
    DesktopOutlined,
    UserOutlined,
    TeamOutlined,
    FileOutlined,
    LogoutOutlined,
    SwapOutlined,
    KeyOutlined,
    MonitorOutlined,
    PaperClipOutlined,
    AuditOutlined,
    SafetyOutlined
  } from '@ant-design/icons';

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const ModelDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { modelId } = useParams(); 
    const userId = useSelector((state) => state.auth.userId);
    const { modelData, modelIsLoading, modelLoadError } = useSelector((state) => state.model);
    const [requiredSteps, setRequiredSteps] = useState([]);

    useEffect(() => {
        // Check if modelId is different or modelData is not loaded yet
        if (userId && modelId && (!modelData || modelData.name !== modelId)) {
            console.log('Fetching model data');
            dispatch(fetchModelData({ userId, modelId }));
        }
      }, [userId, modelId, modelData, dispatch]);

      useEffect(() => {
        if (!modelData) return;
        if (Object.keys(modelData).length === 0) return;
        console.log("Model Data", modelData);  
        const steps = getRequiredSteps(modelData.requirements, true);
        setRequiredSteps(steps);
      }, [modelData])
  
    return (
        <div>
        <Breadcrumb items={[{ title: <a href="/dashboard/">models</a> }, { title: modelId}]} className='breadcrumb'/>
        <div className="pageContent">
            <Spin spinning={modelIsLoading}>
                { modelLoadError ? (
                    <Paragraph type="danger">{modelLoadError}</Paragraph>
                ) : (
                    <>                    
                    { modelData && (
                        <div>
                            {/* <Row>
                                <Title level={2}>{modelData.name}</Title>
                            </Row> */}
                            <Row gutter={16} className='equal-height-cards ' > 
                                <Col md={24} lg={6}>
                                    <Card className='my-2'>
                                        <Title level={4}>Model Details</Title>
                                        <Paragraph>System Name: {modelData.name}</Paragraph>
                                        <Paragraph>Original Assessment ID: {modelData.assessmentId}</Paragraph>
                                        {/* <Paragraph>Created: {modelData.created}</Paragraph> */}
                                        {/* display the requirements */}
                                        <Paragraph> Input/Output: {modelData.systemInfo.inputType}/{modelData.systemInfo.outputType}</Paragraph>
                                        <Title level={5}>Relevant Regulations</Title>
                                        <List>
                                            { modelData.regulations.euProhibited ? <List.Item>Prohibited under EU AI Act</List.Item> : null }
                                            { modelData.regulations.euHighRisk ? <List.Item>High Risk under EU AI Act</List.Item> : null }
                                            { modelData.regulations.euLowRisk ? <List.Item>Low Risk under EU AI Act</List.Item> : null }
                                            { modelData.regulations.nyAutomatedEmployment ? <List.Item>New York Bill 144</List.Item> : null }
                                            { modelData.regulations.coLifeInsurance ? <List.Item>Colorado Life Insurance</List.Item> : null }
                                        </List>
                                    </Card>                                       
                                </Col>
                                <Col md={24} lg={6}>
                                    <Card className='my-2'>
                                        <Title level={4}>Quick Links</Title>
                                        <Row gutter={[16, 16]} justify="center" className="text-center">
                                        <Flex vertical gap="small" style={{ width: '100%' }}>
                                            <Col>
                                            <Button block type="primary btn-primary" onClick={() => navigate(`/models/${modelId}/obligations`)} icon={<PaperClipOutlined />}>
                                                View Obligations
                                            </Button>
                                            </Col>
                                            <Col>
                                            <Button block type="primary btn-primary" onClick={() => navigate(`/models/${modelId}/benchmarking`)} icon={<PieChartOutlined />}>
                                                 Benchmarking
                                            </Button>
                                            </Col>
                                            <Col>
                                            <Button block type="primary btn-primary" onClick={() => navigate(`/models/${modelId}/monitoring`)} icon={<MonitorOutlined />}>
                                                 Monitoring
                                            </Button>
                                            </Col>
                                            <Col>
                                            <Button block type="primary btn-primary" onClick={() => navigate(`/models/${modelId}/eventreview`)} icon={<UserOutlined />}>
                                                 Human Review
                                            </Button>
                                            </Col>
                                            <Col>
                                            <Button block type="primary btn-primary" onClick={() => navigate(`/models/${modelId}/audit`)} disabled icon={<AuditOutlined/>}>
                                                 Generate Audit
                                            </Button>
                                            </Col>
                                            <Col>
                                            <Button block type="primary btn-primary" onClick={() => navigate(`/models/${modelId}/screeningpolicy`)} icon={<SafetyOutlined />}>
                                                 Screening Policy
                                            </Button>
                                            </Col>
                                            <Col>
                                            <Button block type="primary btn-primary" onClick={() => navigate(`/models/${modelId}/paperwork`)} icon={<FileOutlined />}>
                                                 Paperwork
                                            </Button>
                                            </Col>
                                        </Flex>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col md={24} lg={12}>
                                    <Card className="items-center text-center justify-center my-2" >
                                        <Title level={4}>Your Compliance Checklist:</Title>
                                        <Row>  
                                            <Steps
                                                direction="vertical"
                                                size="large"
                                                items={requiredSteps}
                                            />
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row >
                                <Col span={24}>
                                <Card tab="Events" key="events" className='my-6'>
                                    <Title level={4}>Recent Events</Title>
                                    <MonitoredEvents userId={userId} modelId={modelId} nEvents={10}/>
                                </Card>
                                </Col>
                            </Row>
                        </div>
                    ) }
                    </>
                )}
            </Spin>
        </div>
        </div>
    );
};

export default ModelDashboard;