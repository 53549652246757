import React, { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Layout, Typography, Row, Col, Card, Input, Button, Alert, Switch, Checkbox, Spin, List} from 'antd';
import { Divider, Steps } from 'antd';
import { modelDataFromJot, getRequiredSteps } from "../features/models/modelJotLogic"
import { siteConfig } from '../config/site';

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;
const { Search } = Input;

const Assessment = () => {

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [jotId, setJotId] = useState(''); 
    const [jotResponse, setJotResponse] = useState(null);
    const [error, setError] = useState('');
    const [modelData, setModelData] = useState({});
    const [requiredSteps, setRequiredSteps] = useState([]);
    const [pdfRequested, setPdfRequested] = useState(false);
    const [loading, setLoading] = useState(false);

    // Read the 'id' query parameter and set it as the input value
    useEffect(() => {
      const id = searchParams.get('id'); // Get the 'id' query parameter
      if (id) {
        setJotId(id); 
      };
    }, [searchParams]); // Depend on searchParams to re-run when URL changes

    // test id: 5875564497823953456
    useEffect(() => {
      if (!jotId) return;
      const fetchAssessmentData = async (jotId) => {
        setLoading(true);
        console.log("return for", jotId);
        if (!jotId) return;
        try {
          console.log("fetching data for", jotId);
          const response = await fetch('https://us-central1-scalesafe-firebase.cloudfunctions.net/get_assessment?submissionID='+jotId);
          const data = await response.json();
          setJotResponse(data);
          console.log(data);
        } catch (error) {
          setError(error);
        } 
        setLoading(false);
      };
      fetchAssessmentData(jotId);
    }, [jotId]);

    // Update the requirements state when the jotResponse changes
    useEffect(() => {
      if (!jotResponse) return;
      if (!(jotResponse.content && jotResponse.content.answers)) return;
      // Check if jotResponse contains the expected structure
      const answers = jotResponse.content.answers;
      setModelData(modelDataFromJot(answers));
    }, [jotResponse]);


    useEffect(() => {
      if (!modelData) return;
      if (Object.keys(modelData).length === 0) return;
      console.log("Model Data", modelData);  
      const steps = getRequiredSteps(modelData.requirements);
      setRequiredSteps(steps);
    }, [modelData])



    return (
      <Layout className="min-h-screen m-10">
        <Content className="p-20">
          <div className="flex flex-col items-center justify-center">
            <Title level={1} className="text-center pt-10">Assessment Results</Title>
            <div className='items-center min-w-lg'>
              <>
                <Row gutter={[24, 24]} justify="center">
                  <Col xs={24} sm={18} md={12} lg={10}>
                        <Card>
                              { jotId ? 
                                <>
                                  <Title level={4}>Enter Another Assessment Code:</Title>
                                  <Search
                                    placeholder="5875564497823953456"
                                    defaultValue={jotId}
                                    variant="filled"
                                    name="jotIdInput"
                                    onSearch={value => {setJotId(value); setSearchParams({ id: value });}}
                                  /> 
                                </> : <> 
                                  <Title level={4}>Enter Assessment Code:</Title>
                                  <Search
                                      placeholder="Assessment Code (e.g. 5875564497823953456)"
                                      name="jotIdInput"
                                      onSearch={value => {setJotId(value); setSearchParams({ id: value });}}
                                  /> 
                                </>
                                }
                        </Card>
                    </Col>
                </Row>
                {loading ? <Spin className="mt-6" /> :
                <Row justify="center">
                  <Col xs={24} sm={18} md={12} lg={10}>
                          {jotId && modelData && Object.keys(modelData).length > 0 ? (
                              <>
                              {/* { modelData.regulations.euProhibited || modelData.regulations.euHighRisk || modelData.regulations.euLowRisk || modelData.regulations.nyAutomatedEmployment || modelData.regulations.coLifeInsurance ?
                              <Card className="items-center text-center justify-center" >
                                <Title level={4}>Relevant Regulations</Title>
                                  <List style={{textAlign: 'center'}}>
                                      { modelData.regulations.euProhibited ? <List.Item >Prohibited under EU AI Act</List.Item> : null }
                                      { modelData.regulations.euHighRisk ? <List.Item>High Risk under EU AI Act</List.Item> : null }
                                      { modelData.regulations.euLowRisk ? <List.Item>Low Risk under EU AI Act</List.Item> : null }
                                      { modelData.regulations.nyAutomatedEmployment ? <List.Item>New York Bill 144</List.Item> : null }
                                      { modelData.regulations.coLifeInsurance ? <List.Item>Colorado Life Insurance</List.Item> : null }
                                  </List>
                              </Card>
                              : null
                              } */}
                              <Card className="items-center text-center justify-center" >
                                <Title level={4}>How to start complying:</Title>
                                <Row>  
                                  <Steps
                                    direction="vertical"
                                    size="large"
                                    items={requiredSteps}
                                  />
                                </Row>
                                <Checkbox className="mt-4" onChange={(e) => {setPdfRequested(e.target.checked)}}>Send me a detailed breakdown PDF of my requirements.</Checkbox>
                                <br />
                                <Button type="primary btn-primary mt-6" onClick={() => navigate({pathname:'/login', search: `?${createSearchParams({id: jotId, pdfRequested: pdfRequested})}`})}>Go to Login</Button>  
                              </Card>
                              </>
                          ) : (
                            <Card className="items-center text-center justify-center" >
                                  <Title level={4}>What requirements do we have in store? Fill out the <a href="https://scalesafe.ai/assess">assessment form</a> and enter the code above.</Title>
                            </Card>
                          )}
                      
                  </Col>
                </Row> }

                {error && (
                      <Row justify="center" className="mt-6">
                        <Col xs={24} sm={18} md={12} lg={10}>
                            <Alert type="error" message={error} />
                        </Col>
                      </Row>  
                    )}
              </>
              </div>
          </div>
        </Content>
              
        <Footer className="text-center p-4 bg-gray-100">
            © 2024 ScaleSafe. Problems? Contact team@scalesafe.ai and we'll find a solution.
        </Footer>
      </Layout>
    );
};

export default Assessment;
