import { collection, query, where, getDocs, doc, updateDoc, limit, orderBy } from 'firebase/firestore/lite';
import { db } from '../../firebase';  

// export async function fetchModelEvents(db, userId, modelId) {
//     try {
//         const eventsCollectionRef = collection(db, `users/${userId}/models/${modelId}/events`);
//         const q = query(eventsCollectionRef);
//         const querySnapshot = await getDocs(q);
//         const modelEvents = querySnapshot.docs.map(doc => ({
//             id: doc.id,
//             ...doc.data()
//         }));
//         return modelEvents;
//     } catch (error) {
//         console.error("Failed to fetch model events:", error);
//         throw new Error("Failed to fetch model events.");
//     }
// }


export const fetchModelEvents = async (db, userId, modelId, nEvents) => {
    console.log('Fetching model events slice', nEvents);
    try {
        const collectionRef = collection(db, `/users/${userId}/models/${modelId}/events`);
        const eventsQuery = query(collectionRef, orderBy("model_end_time", "desc"), limit(nEvents));
        const data = await getDocs(eventsQuery);
        const modelEvents = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        return modelEvents;
    } catch (error) {
        console.error("Failed to fetch model events:", error);
        throw new Error("Failed to fetch model events.");
    }
};

export async function updateEventStatusInStore(db, userId, modelId, eventId, status) {
    try {
        const eventRef = doc(db, `users/${userId}/models/${modelId}/events`, eventId);
        await updateDoc(eventRef, {
            reviewStatus: status
        });
        console.log(`Event status updated to ${status}`);
    } catch (error) {
        console.error("Failed to update event status:", error);
        throw new Error("Failed to update event status.");
    }
}
