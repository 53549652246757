import React, { useState } from "react";
import { Breadcrumb, Layout, Card, Typography, Row, Col, Button} from "antd";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import { siteConfig } from "../config/site";


const { Paragraph, Title} = Typography;
const { Content } = Layout;

const tmpData = {
    company_name: "Tech Innovations Inc.",
    date: new Date().toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }),
    system_description:
      "The system is an advanced deep learning model designed to analyze and provide real-time insights into market trends, utilizing a combination of neural network architectures like recurrent neural networks (RNNs), convolutional neural networks (CNNs), and transformers to process diverse market data, including stock prices, trading volumes, economic indicators, and news feeds. It identifies hidden patterns and correlations, continuously adapting to new information to refine its predictions. Optimized for scalability and low-latency responses, the system supports high-frequency data environments, integrating anomaly detection and predictive analytics to preempt market disruptions. A user-friendly interface allows for customized analysis and intuitive data visualization, enhancing decision-making for traders and financial professionals.",
    benchmarks: [
      { name: "Accuracy Test", result: "95%", status: "passed" },
      { name: "Latency Test", result: "20ms", status: "failed" },
      { name: "Bias Test", result: "NA", status: "todo" },
    ],
    conformity_details:
      "The system complies with all the regulatory standards set by the EU AI Act.",
  };


const Paperwork = () => {

    const { modelId } = useParams(); 
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleDownloadPDF = async () => {
        setLoading(true);
        try {
            const response = await axios.post(siteConfig.api.get_conformity_assessment_url, tmpData, {
                responseType: 'blob', // Set the response type to 'blob' to receive binary data
            });
            if (response.status === 200) {
                console.log("PDF fetched successfully");
                const blob = new Blob([response.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "conformity_assessment.pdf";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error("Failed to fetch PDF");
            }
        } catch (error) {
            console.error("Error fetching PDF:", error);
        }
        setLoading(false);
    };
    




    return (
        <div class="container mx-auto"> 
            <Breadcrumb items={[{ title: <a href="/dashboard/">models</a> }, { title: <a href={`/models/${modelId}/`}>{modelId}</a>}, { title: <a href={`/models/${modelId}/obligations`}>obligations</a>}, { title: "paperwork"}]} className='breadcrumb' />
            <div class="flex flex-col items-center justify-center"> 
                <Title level={1}>Paperwork</Title>
                <div class="bg-white shadow-lg rounded-lg p-8 max-w-2xl"> 
                        {/* <Card bordered={true} className='max-w-xl mx-auto mb-4 p-2 bg-white shadow-lg rounded-lg' style={{ margin: '16px 0' }}>
                            <Title level={2}>Let's talk about paperwork...</Title>
                            <Paragraph>
                                Behind the scenes, we're piecing together a puzzle that's a tad more intricate than anticipated. It turns out, it's not just a form or two, but a ongoing investigation into what the required conformity assessments and technical documentation are required to look like for new AI systems. 
                            </Paragraph>
                            <Paragraph>
                                We will stay in touch with you directly as we talk to regulators and figure it out. Stay tuned, and don't worry, we're on it.
                            </Paragraph>
                            <Row justify="center">
                                <Button type="primary btn-primary" onClick={() => navigate(`/models/${modelId}/monitoring`)}>Next: Monitoring</Button>
                            </Row>
                        </Card> */}
                        <Card bordered={true}>
                            <Title level={2}>Download Conformity Assessment</Title>
                            <Paragraph>
                                Based on your model's requirements, the benchmarking, and monitoring usage, we have generated a conformity assessment PDF for you to download that complies with the EU AI Act.
                            </Paragraph>
                            <Row justify="center">
                                <Button
                                    type="primary btn-primary"
                                    onClick={handleDownloadPDF}
                                    loading={loading}
                                    >
                                    Download Conformity Assessment PDF
                                    </Button>
                            </Row>
                        </Card>
                </div>
            </div>
        </div>
    );
}

export default Paperwork;
