export const modelDataFromJot = (jotData) => {
    console.log('Processing Jot Data')

    if (!jotData) {
        console.log("No Jot Data");
        // raise error
        throw new Error("No Jot Data");
    }
    const modelData = {
      systemInfo:{
        systemType: '',
        inputType: 'text',
        outputType: 'text',
        applications: [],
        development: { 
          offTheShelf: false,
          generalPurpose: false,
          fromScratch: false,
          finetuning: false,
          LLMWrapper: false
        }
      },
      jurisdiction: [],
      regulations: {
        euHighRisk: false,
        euProhibited: false,
        euLowRisk: false,
        nyAutomatedEmployment: false,
        coLifeInsurance: false,
      },
      requirements: {
        isBenchmarking: true,
        benchmarking: {
          required: {
            nyBiasAudit: false,
            coBiasAudit: false,
            euHighRisk_Education: false,
            euHighRisk_Employment: false,
            euHighRisk_Health: false,
            toxicChatExamples: false,
            maketingBiasText: false,
          },
          recommended: {
            customBenchmark: true,
          }
        },
        isPaperwork: true,
        paperwork: {
          basicAudit: true,
          euRiskManagementSystem: false,
          euDataGovernance: false,
          euTechnicalDocumentation: false,
          euConformityAssessment: false,
          nyBiasAudit: false,
          coBiasAudit: false
        },
        isLogging: true,
        logging: {
          basicLogging: true,
          euMonitoring: false
        },
        isScreening: false,
        screening: {
          euHighRiskScreening: false,
          NSFWscreening: false,
          hateSpeechScreening: false,
          companyPolicyScreening: false,
        },
        isHumanIntervention: false,
        humanIntervention: {
          euHighRiskHumanIntervention: false,
        }
      },
      customerData: {
        email: '',
        name: '',
      }
    };

      // Loop through the answers to find the relevant keys
      Object.keys(jotData).forEach(key => {
        const answer = jotData[key];

        if (answer.answer) {

          if (answer.name === 'email') {
            modelData.customerData.email = answer.answer;
          }

          if (answer.name === '"companyName"') {
            modelData.customerData.name = answer.answer;
          }
          
          if  (answer.name === 'jurisdiction') {
            modelData.jurisdiction = answer.answer;
          }

          if (answer.name === '"interfaceType"') {
            modelData.systemInfo.interfaceType = answer.answer;
          }

          if (answer.name === 'applications') {
            modelData.systemInfo.applications = answer.answer;
          }

          if (answer.name === 'inputType') {
              modelData.systemInfo.inputType = answer.answer;
          }

          if (answer.name === 'outputType') {
              modelData.systemInfo.outputType = answer.answer;
          }

          if  (answer.name === 'systemType') {
            if (answer.answer === "Off-the-shelf AI product for specific use case") {
              modelData.systemInfo.development.offTheShelf = true;
            } else if (answer.answer === "General-purpose chat model (e.g., ChatGPT, Gemini, Claude)") {
              modelData.systemInfo.development.generalPurpose = true;
            } else if (answer.answer === "Building AI from scratch (e.g., training a classifier with custom data)") {
              modelData.systemInfo.development.fromScratch = true;
            } else if (answer.answer === "Finetuning an existing model") {
              modelData.systemInfo.development.finetuning = true;
            } else if (answer.answer === "Integrating general-purpose model into a system or prompt engineering (e.g., ChatGPT wrapper)") {
              modelData.systemInfo.LLMWrapper = true;
            }
          }

          if (answer.name === 'newYork' && answer.answer === "1") {
              modelData.regulations.nyAutomatedEmployment = true;
              modelData.requirements.benchmarking.required.nyBiasAudit = true;
              modelData.requirements.paperwork.nyBiasAudit = true;
          }

          if (answer.name === 'euHigh' && answer.answer === "1") {
              modelData.regulations.euHighRisk = true;
              modelData.requirements.paperwork.euRiskManagementSystem = true;
              modelData.requirements.paperwork.euDataGovernance = true;
              modelData.requirements.paperwork.euTechnicalDocumentation = true;
              modelData.requirements.logging.euMonitoring = true;
              modelData.requirements.logging.basicLogging = false;
              modelData.requirements.screening.euHighRiskScreening = true;
              modelData.requirements.humanIntervention.euHighRiskHumanIntervention = true;
          }

          if (answer.name === 'euProduct' && answer.answer === "1") {
            modelData.regulations.euHighRisk = true;
            modelData.requirements.paperwork.euRiskManagementSystem = true;
            modelData.requirements.paperwork.euDataGovernance = true;
            modelData.requirements.paperwork.euTechnicalDocumentation = true;
            modelData.requirements.logging.euMonitoring = true;
            modelData.requirements.screening.euHighRiskScreening = true;
            modelData.requirements.humanIntervention.euHighRiskHumanIntervention = true;
        }

          if (answer.name === 'colorado' && answer.answer === "1") {
              modelData.regulations.coLifeInsurance = true;
              modelData.requirements.benchmarking.required.coBiasAudit = true;
              modelData.requirements.paperwork.coBiasAudit = true;
          }
        }
      });

      if (modelData.jurisdiction.includes('European Union') && !modelData.regulations.euHighRisk && !modelData.regulations.euProhibited) {
        modelData.regulations.euLowRisk = true;
      }

      if (modelData.regulations.euLowRisk) {
        modelData.requirements.screening.euHighRiskScreerning = true;
      }

      // Compound logic
      if (Object.values(modelData.requirements.benchmarking).some(Boolean)) {
        modelData.requirements.isBenchmarking = true;
      }
      if (Object.values(modelData.requirements.paperwork).some(Boolean)) {
        modelData.requirements.isPaperwork = true;
      }
      if (Object.values(modelData.requirements.logging).some(Boolean)) {
        modelData.requirements.isLogging = true;
      }
      if (Object.values(modelData.requirements.screening).some(Boolean)) {
        modelData.requirements.isScreening = true;
      }
      if (Object.values(modelData.requirements.benchmarking).some(Boolean)) {
        modelData.requirements.isBenchmarking = true;
      }
      if (Object.values(modelData.requirements.humanIntervention).some(Boolean)) {
        modelData.requirements.isHumanIntervention = true;
      }
      if (modelData.regulations.euHighRisk && modelData.systemInfo.applications.includes('Education')) {
        modelData.requirements.benchmarking.required.euHighRisk_Education = true;
      }
      if (modelData.regulations.euHighRisk && modelData.systemInfo.applications.includes('Employment')) {
        modelData.requirements.benchmarking.required.euHighRisk_Employment = true;
      }
      if (modelData.regulations.euHighRisk && modelData.systemInfo.applications.includes('Health')) {
        modelData.requirements.benchmarking.required.euHighRisk_Health = true;
      }
      
      return modelData;
};



export const getRequiredSteps = (requirements, loggedIn) => {
  // This function is going to be for an externally facing party to understand what they need to do to meet the requirements.
   const steps = []
   if (!loggedIn) {
      steps.push(
      { 
        title: 'Setup with API Keys and Access', 
        description: "Connect to our systems to get started with your compliance journey." 
      })
    }

  if (requirements.isBenchmarking) {
    steps.push({
      title: 'Jurisdiction Aware Benchmarking',
      description: "ScaleSafe tests your models against benchmarks that meet jurisdictional requirements and surface localized risks."
    });
  } else {
    steps.push({
      title: 'Jurisdiction and Risk Aware Benchmarking',
      description: "ScaleSafe tests your models against benchmarks that meet jurisdictional requirements and surface localized risks."
    });
  }

  steps.push({
    title: 'Continuous Monitoring and Audits',
    description: "ScaleSafe's continuous monitoring and audit system will help you comply with regulations and maintain oversight into model use.",
  });

  if (requirements.paperwork.basicAudit) {
    steps.push({
      title: 'Comprehensive Reports',
      description: "ScaleSafe helps you generate a comprehensive audit that communicates model performance, usage, and risks."
    });
  } else if (requirements.paperwork.euConformityAssessment) {
    steps.push({
      title: 'Conformity Assessment and Technical Documentation',
      description: "ScaleSafe generates a conformity assessments and technical documentation that meet EU requirements."
    });
  } else {
    steps.push({
      title: 'Regulatory Filings',
      description: "ScaleSafe automatically generates the necessary regulatory filings that meet your jurisdiction's requirements."
    });
  }

  // screening
  if (requirements.screening.euHighRiskScreening) {
    steps.push({
      title: 'EU High Risk and Policy Screening',
      description: "ScaleSafe screens model usage in realtime to ensure that models don't engage in EU high risk activities, conform with your company policies, and avoid inappropriate outputs."
    });
  } else {
    steps.push({
      title: 'Safety and Policy Screening',
      description: "ScaleSafe screens model usage in realtime to ensure that models conform with your company policies and avoid inappropriate outputs."
    });
  }

  if (requirements.humanIntervention) {
    steps.push({
      title: 'Human in the Loop Review',
      description: "ScaleSafe provides a human review process to ensure that models to minimize risks and ensure compliance, including emergency stop mechanisms."
    });
  } else {
    steps.push({
      title: 'Emergency Stop Mechanisms',
      description: "ScaleSafe provides emergency stop mechanisms to ensure that models can be stopped in case of inappropriate outputs."
    });
  }

  return steps;
}



export const requirementToBenchmarkMappings = {
  nyBiasAudit: [{json:'nyEmploymentScreening', text:'nyEmploymentScreeningText'}],
};