import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, orderBy, limit, updateDoc, doc } from 'firebase/firestore/lite';
import { db } from '../firebase';
import { Layout, Spin, Card, Button, Typography, Breadcrumb, Collapse, Row, Col} from 'antd';
import { fetchModelEvents, updateEventStatusInStore } from '../features/models/eventSlice';
import { InfoCircleFilled } from '@ant-design/icons';
import { TransitionGroup, CSSTransition } from 'react-transition-group';


const { Content, Header} = Layout;
const { Title, Paragraph } = Typography;

const EventReviewPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { modelId } = useParams();
    const userId = useSelector((state) => state.auth.userId);
    const [modelEvents, setModelEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [nEvents, setNEvents] = useState(10);

    useEffect(() => {
        if (!userId || !modelId) return;
        setIsLoading(true);
        const fetchEvents = async () => {
            try {
                console.log('Fetching model events for model dashboard.', userId, modelId, nEvents);
                const data = await fetchModelEvents(db, userId, modelId, nEvents);
                // filter out events that have already been reviewed
                setModelEvents(data.filter(event => (event.reviewStatus !== 'approved' && event.reviewStatus !== 'denied')));
                setModelEvents(data);
            } catch (err) {
                setError("Failed to fetch model events. Please try refreshing the page.");
            } finally {
                setIsLoading(false);                
            }
        };
        fetchEvents();
    }, [userId, modelId, nEvents]);

    // useEffect(() => {
    //     console.log('Fetching model events completed', modelEvents);
    // }, [modelEvents]);

    const handleUpdateEventStatus = async (eventId, newStatus) => {
        console.log("Updating event status...", eventId, newStatus);
        try {
            // remove the event from the list
            setModelEvents(modelEvents.filter(event => event.id !== eventId));
            await updateEventStatusInStore(db, userId, modelId, eventId, newStatus);
            console.log("Status updated successfully");
            // Optionally refresh or update local state to reflect the new status
        } catch (error) {
            console.error("Error updating event status:", error.message);
        }
    };

    return (
        <div>
            <Breadcrumb items={[{ title: <a href="/dashboard/">models</a> }, { title: <a href={`/models/${modelId}/`}>{modelId}</a>}, { title: <a href={`/models/${modelId}/obligations`}>obligations</a>}, { title: <a href={`/models/${modelId}/monitoring`}>monitoring</a>}, { title: "event review"}]} className='breadcrumb' />
            <div className='pageContent'>
                <Title level={2}>Event Review</Title>
                <div className='contentBox'>
                    <Spin spinning={isLoading}>
                        {error && <Paragraph>Error: {error}</Paragraph>}
                        {!isLoading && (
                        <>
                        <Paragraph>There are {modelEvents.length} events to review. Showing most recent {Math.max(modelEvents.length, nEvents)}.</Paragraph>
                        <TransitionGroup>
                            {modelEvents.map(event => (
                                <CSSTransition
                                key={event.id}
                                timeout={500}
                                classNames="event"
                                >
                                    <Collapse
                                        className='my-2'
                                        items={[{ key: '1', 
                                        label: `API Call - ${new Date(event.model_start_time * 1000).toLocaleString()}`,
                                        children: 
                                        <>
                                            <Paragraph>Model Inputs: {JSON.stringify(event.model_inputs)}</Paragraph>
                                            <Paragraph>Model Outputs: {JSON.stringify(event.model_outputs)}</Paragraph>
                                            <Row justify={'center'}>
                                            <Button type="primary" className="btn-primary mx-2" onClick={() => handleUpdateEventStatus(event.id, 'approved')}>Approve</Button>
                                            <Button classname="mx-2" onClick={() => handleUpdateEventStatus(event.id, 'review')}>Flag for Review</Button>
                                            <Button type="danger" className="btn-danger mx-2" onClick={() => handleUpdateEventStatus(event.id, 'denied')}>Deny</Button>
                                            </Row>
                                        </>,
                                        extra: <InfoCircleFilled style={{color: 'red'}}/>
                                        }]}
                                        />
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                        </>
                        )}
                    </Spin>
                </div>
            </div>
        </div>
    );
};

export default EventReviewPage;
