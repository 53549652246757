import React, { useState } from 'react';
import { siteConfig }  from '../config/site';
import { Link, useNavigate, Outlet, useParams} from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { Layout, Menu, Modal } from 'antd';
import { MiniModelList } from './ModelsList';
import {
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
  LogoutOutlined,
  SwapOutlined,
  KeyOutlined,
  MonitorOutlined,
  PaperClipOutlined,
  SafetyOutlined,
  AuditOutlined
} from '@ant-design/icons';
import { ReactComponent as Logo } from '../assets/sidelogo-white.svg';
import { ReactComponent as LogoSmall } from '../assets/shield-white.svg';

const { Content, Footer, Sider } = Layout;

const Navigation = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [logo, setLogo] = useState(<Logo />);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { modelId } = useParams(); 


  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
      if (collapsed) {
        setTimeout(() => {
          setLogo(<LogoSmall className='p-2'/>);
        }, 200); // 1000 milliseconds delay
      } else {
        setLogo(<Logo />);
      }
    
  }

  const handleMenuClick = (e) => {
    if (e.key === 'logout') {
      handleLogout();
    } else if (e.key === 'switch_model') {
      showModal();
    } else {
      navigate(e.key);
    }
  };

  const items = [
    {
      key: siteConfig.pages.home.route(),
      icon: <DesktopOutlined />,
      label: <Link to={siteConfig.pages.home.route()}>Home</Link>
    },
    {
        key: siteConfig.pages.assessment.route(),
        icon: <FileOutlined />,
        label: <Link to={siteConfig.pages.assessment.route()}>New Assessment</Link>
    },
    {
        key: siteConfig.pages.modelDashboard.route(modelId),
        icon: <DesktopOutlined />,
        label: <Link to={siteConfig.pages.modelDashboard.route(modelId)}>Model Dashboard</Link>,
        disabled: modelId ? false : true,
    },
    {
        key: siteConfig.pages.obligations.route(modelId),
        icon: <PaperClipOutlined />,
        label: "Obligations",
        disabled: modelId ? false : true,
        children: [
            {
                key: siteConfig.pages.benchmarking.route(modelId),
                icon: <PieChartOutlined />,
                label: <Link to={siteConfig.pages.benchmarking.route(modelId)}>Benchmarking</Link>
            },
            {
                key: siteConfig.pages.paperwork.route(modelId),
                icon: <FileOutlined />,
                label: <Link to={siteConfig.pages.paperwork.route(modelId)}>Paperwork</Link>
            },
            {
                key: siteConfig.pages.monitoring.route(modelId),
                icon: <MonitorOutlined />,
                label: <Link to={siteConfig.pages.monitoring.route(modelId)}>Monitoring</Link>
            },
            {
                key: siteConfig.pages.eventReview.route(modelId),
                icon: <UserOutlined />,
                label: <Link to={siteConfig.pages.eventReview.route(modelId)}>Human Review</Link>
            },
            {
                key: siteConfig.pages.audit.route(modelId),
                icon: <AuditOutlined />,
                label: <Link to={siteConfig.pages.audit.route(modelId)}>Generate Audit</Link>,
                disabled: true
            },
            {
                key: siteConfig.pages.screeningPolicy.route(modelId),
                icon: <SafetyOutlined />,
                label: <Link to={siteConfig.pages.screeningPolicy.route(modelId)}>Screening Policy</Link>,
            }
        ]
    },
    {
        key: siteConfig.pages.apiKeys.route(modelId),
        icon: <KeyOutlined />,
        label: <Link to={siteConfig.pages.apiKeys.route(modelId)}>API Keys</Link>,
        disabled: modelId ? false : true,
    }
  ];

  const bottomItems = [
    // {
    //     key: 'switch_model',
    //     icon: <SwapOutlined />,
    //     label: 'Switch Model',
    //     type: 'button'
    //   },
      {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: 'Logout',
        type: 'button'
      }
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} >
            <div className="logo" style={{ height: '32px', margin: '16px'}}>
              {logo}
            </div>        
            <Menu
            theme="dark"
            defaultSelectedKeys={['1']}
            mode="inline"
            items={items}
            onClick={handleMenuClick}
            inlineCollapsed={false}
            />
            <Menu
            theme="dark"
            mode="inline"
            className="menu-bottom"
            items={bottomItems}
            onClick={handleMenuClick}
            // style={{ position: 'absolute', bottom: '200px', width: '100%'}}
            />
        </Sider>
        <Layout>
          <Content style={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
            <Outlet />
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            © 2024 ScaleSafe. Problems? Contact team@scalesafe.ai and we'll find a solution.
          </Footer>
        </Layout>
        {/* <Modal
            title="Select a Model"
            open={isModalVisible}
            // onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            >
            <MiniModelList/>
        </Modal> */}
    </Layout>
  );
};

export default Navigation;
