import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModelsList from './ModelsList';
import { db } from '../firebase';
import { Layout, Typography, Row, Col, Card, Modal, Input, Button, Radio } from 'antd';
import {  query, collection, where, getDocs, setDoc, doc} from 'firebase/firestore/lite';
import { modelDataFromJot } from "../features/models/modelJotLogic";
import { siteConfig } from '../config/site';

const { Paragraph, Title } = Typography;



const NewModelModel = ( { newModelModal, setNewModelModal, passedAssessmentId, pdfRequested }) => {
    
    const [assessmentId, setAssessmentId] = useState(passedAssessmentId);
    const userId = useSelector((state) => state.auth.userId);
    const { Search } = Input;
    const [modelName, setModelName] = useState('');
    const [newNameWarning, setNewNameWarning] = useState('')
    const [searchJotIdWarning, setSearchJotIdWarning] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (passedAssessmentId) {
            setAssessmentId(passedAssessmentId);
        }
    }, [passedAssessmentId]);

    const validateInput = (input) => {
        const regex = /^[a-zA-Z-]+$/;
        return regex.test(input);
    };

    const get_jot_data = async (jotId) => {
        if (!jotId) return;
        setLoading(true);
        console.log("return for", jotId);
        if (!jotId) return;
        try {
            console.log("fetching data for", jotId);
            const response = await fetch('https://us-central1-scalesafe-firebase.cloudfunctions.net/get_assessment?submissionID='+jotId);
            const data = await response.json();
            console.log('jotData', data);
            setLoading(false);
            return data;
        } catch (error) {
            setError(error);
            console.error('Error fetching Jot data:', error);
            setLoading(false);
        } 
    };
    
    const handleInputChange = (e) => {
        const inputValue = e.target.value.toLowerCase();
        // If the input is empty or matches the regex, update the state
        if (inputValue === '' || validateInput(inputValue)) {
          setModelName(inputValue);
          setNewNameWarning('');
        } else {
          // Optionally, show feedback to the user about invalid input
          setNewNameWarning('Only letters and dashes are allowed.');
        }
      };
 
    const handleOk = async () => {
        console.log('Model Name:', modelName);
        console.log('Assessment ID:', assessmentId);
        // Add logic to create the model
        if (modelName === '') {
            setNewNameWarning('Please enter a model name.');
            return;
        }

        if (!assessmentId) {
            setSearchJotIdWarning('Please enter an assessment code and click the search icon.');
            return;
        } else {
            setSearchJotIdWarning('');
        }

        const jotResponse = await get_jot_data(assessmentId);
        console.log('jotResponse', jotResponse);

        if (!jotResponse) {
            setSearchJotIdWarning('Invalid assessment code. Please enter a valid assessment code.')
            return;
        }
        if (!(jotResponse.content && jotResponse.content.answers)){
            setSearchJotIdWarning('Invalid assessment code. Please enter a valid assessment code.')
            return;
        }
        // Check if jotResponse contains the expected structure
        const answers = jotResponse.content.answers;
        const modelData = modelDataFromJot(answers);
        console.log('modelData', modelData)

        const modelsRef = collection(db, "users", userId, "models");
        const q = query(modelsRef, where("name", "==", modelName));

        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                // Model does not exist, proceed to add a new document
                await setDoc(doc(modelsRef, modelName), {
                    name: modelName,
                    assessmentId: assessmentId,
                    created: new Date(),
                    pdfRequested: pdfRequested,
                    ...modelData,
                    jurisdiction: [...(modelData.jurisdiction || [])]
                });
                console.log('jurisdiction', modelData.jurisdiction);
                console.log('Model successfully added.');
                setNewModelModal(false);
                navigate(siteConfig.obligations.route(modelName));
            } else {
                // Model already exists
                console.error('Model with this name already exists.');
                setNewNameWarning('Model with this name already exists. Please choose a different name.');
            }
        } catch (error) {
            console.error('Error checking for existing model or adding new model:', error);
        }
    };

    return (
        <Modal title="Create a new model" open={newModelModal} onOk={handleOk} onCancel={() => setNewModelModal(false)} okButtonProps={{className: "btn-primary"}} okText="Create Model">
                <Paragraph>Let's give this model a name that we will identify it by. Use only text and dashes.</Paragraph>
                <Input 
                placeholder="Model Name" 
                onChange={handleInputChange}
                status={newNameWarning === '' ? '': 'error' }
                />
                {newNameWarning && <Paragraph type="danger">{newNameWarning}</Paragraph>}
                { assessmentId ? 
                    <>
                        <Title level={5} className='mt-4'>Assessment Code:</Title>
                        <Search
                            value={assessmentId}
                            variant="filled"
                            name="jotIdInput"
                            disabled = {true}
                            className='mt-1'
                        /> 
                    </> : <> 
                        <Title level={5} className='mt-4'>Enter Assessment Code:</Title>
                        <Search
                            placeholder="Assessment Code (e.g. 5875564497823953456)"
                            name="jotIdInput"
                            onSearch={value => {setAssessmentId(value); ;}}
                            className='mt-1 mb-3'
                        /> 
                        {searchJotIdWarning && <Paragraph type="danger">{searchJotIdWarning}</Paragraph>}
                        <Paragraph>If you need an assessment code, you can get one from the <a href={siteConfig.externalLinks.assess}>assessment form</a> or duplicate an existing model. </Paragraph>
                    </>
                }
        </Modal>
    );
};

export default NewModelModel;