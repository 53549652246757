import React, { useEffect, useState } from "react";
import { Modal, Input, Form } from 'antd';
import FirebaseUIReact from "firebaseui-react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, getDoc } from 'firebase/firestore/lite';
import { auth, db } from '../firebase';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Typography, Button } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

function Login() {
    const [user, setUser] = useState(auth.currentUser);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            if (user) {
                checkAndCreateUserDocument(user);
            }
        });
        return () => unsubscribe();
    }, []);

    const navigateWithCurrentQueryParams = (path) => {
        const nextPath = `${path}${location.search}`;
        navigate(nextPath);
    };

    const checkAndCreateUserDocument = async (user) => {
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);
        if (!docSnap.exists()) {
            setIsModalVisible(true);  // Show modal for new user data if user document doesn't exist
        } else {
            navigateWithCurrentQueryParams('/dashboard');
        }
    };

    const handleFormSubmit = async (values) => {
        const { primaryUserName, primaryUserEmail, organisation, industry } = values;
        const userRef = doc(db, "users", user.uid);

        try {
            await setDoc(userRef, {
                displayName: primaryUserName,
                email: primaryUserEmail,
                organisation: organisation,
                industry: industry,
                createdAt: new Date()
            });
            // console.log("User document created in Firestore");
            setIsModalVisible(false);
            navigateWithCurrentQueryParams('/dashboard');
        } catch (error) {
            console.error("Error creating new user document:", error);
        }
    };

    const config = {
        continueUrl: "http://localhost:3000/dashboard",
        signInOptions: ["emailpassword", "emaillink"],
        // signInFlow: "redirect",
        // callbacks: {
        //     signInSuccessWithAuthResult: function (login) {
        //         console.log("successfully authenticated", login);
        //         createNewUserDocument(login.user);
        //         navigateWithCurrentQueryParams('/dashboard')
        //     },

        //     signInFailure: function (error) {
        //       console.log("somtin went wrong :9 :((");
        //       console.error(error);
        //     },
        //   },
          passwordSpecs: { minCharacters: 6, },
    };


    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content className="flex flex-col justify-center items-center min-h-screen py-12 px-6">
                <div className="bg-white shadow-md rounded-md p-8 text-center max-w-sm">
                    <Title level={2}>My App</Title>
                    {user ? (
                        <>
                            <Paragraph>You are already signed in as {user.displayName}!</Paragraph>
                            <Button type="primary btn-primary" block onClick={() => navigateWithCurrentQueryParams('/dashboard')}>Go to Dashboard</Button>
                        </>
                    ) : (
                        <div>
                            <Paragraph className="mb-3">Please sign-in:</Paragraph>
                            <div style={{ width: '300px' }}> {/* Adjusted width */}
                                <FirebaseUIReact auth={auth} config={config} />
                            </div>
                        </div>
                    )}
                </div>
                <Modal title="Complete Your Profile" open={isModalVisible} onOk={form.submit} onCancel={() => setIsModalVisible(false)} okButtonProps={{className: "btn-primary"}}>
                    <Form form={form} onFinish={handleFormSubmit}>
                        <Form.Item name="primaryUserName" rules={[{ required: true, message: 'Please input your name!' }]}>
                            <Input placeholder="Primary User Name" />
                        </Form.Item>
                        <Form.Item name="primaryUserEmail" rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}>
                            <Input placeholder="Primary User Email" />
                        </Form.Item>
                        <Form.Item name="organisation" rules={[{ required: true, message: 'Please input your organisation!' }]}>
                            <Input placeholder="Organisation" />
                        </Form.Item>
                        <Form.Item name="industry" rules={[{ required: true, message: 'Please input your organisation!' }]}>
                            <Input placeholder="Primary Industry" />
                        </Form.Item>
                    </Form>
                </Modal>
            </Content>
        </Layout>
    );
}

export default Login;
