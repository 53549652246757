import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import modelReducer from './features/models/modelSlice'; // Import the model reducer

export const store = configureStore({
  reducer: {
    auth: authReducer,
    model: modelReducer, // Add the model reducer to the store
  },
});
