import React, { useState } from "react";
import { Breadcrumb, Layout, Card, Typography, Row, Col, Button, Input} from "antd";
import { useParams, useNavigate } from 'react-router-dom';

const { Paragraph, Title} = Typography;
const { Content, Header} = Layout;
const { TextArea } = Input;

const starterPolicy = `# Screening Policy
(Critical) Avoid Discrimination: Do not engage in or promote discrimination based on race, gender, age, disability, origin, religion, or any other protected status.

(Critical) No Hate Speech: Do not produce or endorse hate speech or derogatory or harmful content towards any group or individual. 

(Critical) Maintain Confidentiality: Do not disclose any customer’s personal or sensitive information.

(Warning) Accurate and Approved Pricing Only: Do not state or imply pricing information unless it is provided and verified by our systems. Unauthorized price communication could mislead customers and violate trade regulations.

(Warning) Respect Other Companies: Avoid making derogatory remarks about competitors. Maintain a professional and respectful tone in all communications.

(Critical) No False Claims: Do not make false or unverifiable claims about our services or benefits. Ensure accurate data support all statements.

(Warning) Appropriate Tone and Language: Use a professional and respectful tone in all communications. Avoid slang, jargon, or language that could be considered inappropriate or unprofessional in a business context.

(Warning) No Unauthorized Advice: Do not provide legal, medical, or any professional advice unless the system is explicitly designed and certified to offer such guidance. 
`

const ScreeningPolicyUpdate = () => {
    const { modelId } = useParams(); 
    const [policy, setPolicy] = useState(starterPolicy);


    return (
        <div>
        <Breadcrumb items={[{ title: <a href="/dashboard/">models</a> }, { title: <a href={`/models/${modelId}/`}>{modelId}</a>}, { title: <a href={`/models/${modelId}/obligations`}>obligations</a>}, { title: "screening policy"}]} className='breadcrumb' />
        <div class="pageContent max-w-7xl"> 
            <Title level={2}>Update Usage Screening Policy</Title>
            <div class="contentBox"> 
                <Row justify="center" align="middle">
                <Col span={12} xs={24} sm={24} className="flex-1 space-y-4">
                <Title level={5}>What is a system screening policy?</Title>
                    <Paragraph>
                        AI training data often includes behaviors and outputs that we never want to see. Model training can help reduce the likelihood of these behaviors, but it can't eliminate them entirely. A system screening policy is a set of rules that help you identify and respond to these behaviors when they occur.
                    </Paragraph>
                    <Paragraph>
                        You can write a written language rule set (see the example) that will be used by a supervising AI system to ensure that AI system outputs are consistent with your organization's values and policies. This rule set can be used to screen out harmful or inappropriate content, or to flag content for human review.
                    </Paragraph>
                    <Button type="primary btn-primary" className="mt-5">Update Policy</Button>
                </Col>
                <Col justify="center" span={12} sm={24} xs={24} className="flex-1 ml-0 md:ml-10 mt-10 md:mt-0">
                    <Title level={5}>Update Policy</Title>
                    <TextArea rows={20} value={policy} onChange={(e) => setPolicy(e.target.value)}
                    placeholder="Company Screening Policy goes here" maxLength={2000}/>
                
                </Col>
                </Row>
            </div>
        </div>
        </div>
    );
}

export default ScreeningPolicyUpdate;
