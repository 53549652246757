import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModelData } from '../features/models/modelSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, query, collection, getDocs } from 'firebase/firestore/lite';
import { Spin, Card, Button, Layout, Typography, Row, Col, Modal} from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// Depending on your theme preference, you can change the style import
import { materialDark as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { requirementToBenchmarkMappings } from '../features/models/modelJotLogic';
import { db } from '../firebase';
import { createApiKey } from './APIKeys';
import { Avatar, List, Space, Badge, Breadcrumb} from 'antd';
import { Tooltip } from 'antd';
import Link from 'antd/es/typography/Link';


const { Content, Header } = Layout;
const { Title, Paragraph, Text} = Typography;

const benchmarkingCode = `from scalesafe.benchmarking import Benchmarker
dataset = Benchmarker('nyEmploymentScreening')

for item in dataset:
    print(item)  # Process the batch here to do AI
    result = True if np.random.random() > 0.5 else False 
    dataset.answer(result, item['id'])  # Add responses to the buffer

dataset.post_answers() # We send them for auditing
`;


const Benchmarking = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { modelId } = useParams(); 
    const userId = useSelector((state) => state.auth.userId);
    const { modelData, modelIsLoading, modelLoadError } = useSelector((state) => state.model);

    useEffect(() => {
        // Check if modelId is different or modelData is not loaded yet
        if (userId && modelId && (!modelData || modelData.name !== modelId)) {
            console.log('Fetching model data');
            dispatch(fetchModelData({ userId, modelId }));
        }
      }, [userId, modelId, modelData, dispatch]);

    const [benchmarksRaw, setBenchmarksRaw] = useState([]);
    const [modelBenchmarks, setModelBenchmarks] = useState([]);
    const [benchmarks, setBenchmarks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (modelLoadError) setError(modelLoadError);
        if (modelIsLoading) setIsLoading(modelIsLoading);
    }, [modelIsLoading, modelLoadError]);


    useEffect(() => {
      const fetchBenchmarks = async () => {
        setIsLoading(true);
        setError(null);
  
        try {
          // Adjust the path to match your Firestore structure for benchmarks
          const collectionRef = collection(db, `benchmarks`);
          const querySnapshot = await getDocs(collectionRef);
  
          if (!querySnapshot.empty) {
            const benchmarksData = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              // Additional processing or filtering can be done here
            }));
            // console.log('Benchmarks:', benchmarksData);
            setBenchmarksRaw(benchmarksData);
          }
        } catch (err) {
          setError('Failed to fetch benchmarks. Please try refreshing the page.');
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchBenchmarks();


      const fetchUserBenchmarks = async () => {
        setIsLoading(true);
        setError(null);
          try {
            // Adjust the path to match your Firestore structure for user benchmarks
            const collectionRef = collection(db, `users/${userId}/models/${modelId}/benchmarks`);
            const querySnapshot = await getDocs(collectionRef);
    
            if (!querySnapshot.empty) {
              const benchmarksData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                // We may need to convert timestamps to Date objects
              }));
              // console.log('User Benchmarks:', benchmarksData);
              setModelBenchmarks(benchmarksData);
            }
          } catch (err) {
            setError('Failed to fetch user benchmarks. Please try refreshing the page.');
            console.error(err);
          } finally {
            setIsLoading(false);
          }
        }
        
        fetchUserBenchmarks();

    }, [userId, modelId]); 
  
    useEffect(() => {
      try {
        if (benchmarksRaw.length > 0 && modelData) {
          // First we see what has been completed
          const updatedBenchmarks = benchmarksRaw.map(benchmark => {
            const matchingModelBenchmark = modelBenchmarks.find(modelBenchmark => modelBenchmark.id === benchmark.id);
            if (matchingModelBenchmark) {
              return {
                ...benchmark,
                status: matchingModelBenchmark.status,
              };
            }
            return benchmark;
          });

          setBenchmarks(updatedBenchmarks);

          // Next we check what benchmarks are required
          console.log('Model Data Requirements:', modelData.requirements);

          // Convert requirement mappings to a flat list of required benchmark IDs for easier lookup
          const requiredBenchmarkIds = Object.keys(modelData.requirements.benchmarking.required)
              .reduce((acc, requirement) => {
                  if (modelData.requirements.benchmarking.required[requirement]) { // Check if requirement is set
                      const benchmarksForRequirement = requirementToBenchmarkMappings[requirement] || [{text:requirement}];
                      const requirementElement = benchmarksForRequirement.map(element => element[modelData.inputType] || element.text);
                      return acc.concat(requirementElement);
                  }
                  return acc;
              }, []);

          const recommendedBenchmarkIds = Object.keys(modelData.requirements.benchmarking.recommended)
              .reduce((acc, requirement) => {
                  if (modelData.requirements.benchmarking.recommended[requirement]) { // Check if requirement is set
                      const benchmarksForRequirement = requirementToBenchmarkMappings[requirement] || [{text:requirement}];
                      const requirementElement = benchmarksForRequirement.map(element => element[modelData.inputType] || element.text);
                      return acc.concat(requirementElement);
                  }
                  return acc;
            }, []);
          console.log('Required Benchmark IDs:', requiredBenchmarkIds, recommendedBenchmarkIds);

          // Update benchmarks with 'required' attribute
          const requiredBenchmarks = updatedBenchmarks.map(benchmark => {
              return {
                  ...benchmark,
                  required: requiredBenchmarkIds.includes(benchmark.id),
                  recommended: recommendedBenchmarkIds.includes(benchmark.id),
              };
          });

          setBenchmarks(requiredBenchmarks);
        }
      } catch (err) {
        console.error('Error updating benchmarks:', err);
      }
    }, [benchmarksRaw, modelBenchmarks, modelData]);   



    const [newAPIKey, setNewAPIKey] = useState('');

    const getAPIKey = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const apiKey = await createApiKey(userId, modelId);
            setNewAPIKey(apiKey); 
        } catch (err) {
            setError('Failed to create API key. Please try again.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const sortBenchmarks = (benchmarks) => {
      return [...benchmarks].sort((a, b) => {
        const getPriority = (item) => {
          if (item.required) return 1;       // Highest priority for required
          if (item.recommended) return 2;    // Second priority for recommended
          return 3;                          // Lowest priority for others
        };
        return getPriority(a) - getPriority(b);
      });
    };
    

  return (
    <div>
      <Breadcrumb items={[{ title: <a href="/dashboard/">models</a> }, { title: <a href={`/models/${modelId}/`}>{modelId}</a>}, { title: <a href={`/models/${modelId}/obligations`}>obligations</a>}, { title: "benchmarking"}]} className='breadcrumb' />
      <div class="pageContent max-w-7xl"> 
        <Title level={2}>Benchmarking Requirements</Title>
        <div class="contentBox"> 
              {isLoading && <Spin />}
              {error && <Paragraph>{error}</Paragraph>}
              {!isLoading && !error && modelData && (
                <>
                <Row>
                  <Col span={12} xs={24} sm={24} className="flex-1 space-y-4">
                    <Title level={4} >For <Text code style={{ fontWeight: 'bold', fontSize: 'large' }}>{modelData.name}</Text></Title>
                    <Paragraph>
                      Based on your model's requirements, the following benchmarks are needed:
                    </Paragraph>
                    <ul className="list-disc pl-5">
                      {modelData.requirements.newYork && <li>NYC Bill 144 Compliance</li>}
                      {modelData.requirements.euHigh && <li>EU GDPR High Risk Processing</li>}
                    </ul>
                    <Title level={4}>Wondering how to benchmark?</Title>
                    <Paragraph>
                      Benchmarking with ScaleSafe is easy. We don't need to see or deploy your model, we trust you to do that. Simply use the ScaleSafe Python or npm packages to benchmark your model.
                    </Paragraph>

                    <Row justify="center">
                      <Tooltip title="Click to copy" trigger="hover">
                        <div className="commandBox">
                          <Typography.Text copyable style={{ fontWeight: 'bold' }} className='commandText'>
                            pip install scalesafe
                          </Typography.Text>
                        </div>
                      </Tooltip>
                    </Row>
                    <Paragraph>Install the ScaleSafe package and use an API key that corresponds to this model to start benchmarking. The API key will let us know your account and model, but you will need to include the name of each benchmark you run. You can reuse API keys as much as you want. </Paragraph>
                    <Row justify="center">
                      {newAPIKey ? (
                        <Paragraph>API Key: <Text code style={{ fontWeight: 'bold' }}>{newAPIKey}</Text></Paragraph>
                      ) : (
                        <Button type="primary btn-primary" className="mt-2" onClick={getAPIKey}>
                          Get New API Key
                        </Button>
                      )}
                    </Row>
                    <Paragraph>Now you can run benchmarking. Details can be found on <Link href="https://github.com/ScaleSafe/scalesafe">Github</Link>. Here's an example of how to use the ScaleSafe Python package:</Paragraph>
                    <Row justify="center">
                        <SyntaxHighlighter language="python" style={codeStyle} className="rounded-lg mt-2">
                            {benchmarkingCode}
                        </SyntaxHighlighter>
                    </Row>
                  </Col>

                  <Col justify="center" span={12} sm={24} xs={24} className="flex-1 ml-0 md:ml-10 mt-10 md:mt-0">
                    <div style={{ maxHeight: '700px', overflowY: 'auto' }}>
                      {sortBenchmarks(benchmarks).map((benchmark) => (
                        <Card key={benchmark.id} className="mb-4">
                          <Title level={4} className={benchmark.required ? '' : 'text-gray-500'}>
                            {benchmark.name}
                          </Title>
                          <Text>{benchmark.description}</Text>
                          <br />
                          <Text className="font-mono text-sm mr-10">
                            {benchmark.id}
                          </Text>
                          <div className="flex justify-end">
                            {benchmark.status=== "Ready for Review" ?
                              <Badge status={ 'success' } text={benchmark.status} />
                              : 
                              <Badge status={benchmark.required || benchmark.recommended ? 'warning' : 'default'} text={"Not Completed"} className="ml-2" />
                            }
                            {benchmark.required ? 
                              <Badge status={'warning'} text={"Required"} className="ml-2" />
                              : ( benchmark.recommended ?
                              <Badge status={'processing'} text={"Recommended"} className="ml-2" />
                              :
                              <Badge status={'default'} text={"Optional"} className="ml-2" />
                            )}
                          </div>
                        </Card>
                      ))}
                    </div>
                  </Col>
                  </Row>
                </>
              )}
        </div>
      </div>
    </div>
  );  
};

export default Benchmarking;
