import React from "react";

export const providerStyles = {
  "google.com": {
    icon: (
      <svg
        style={{ height: '1.25rem', width: '1.25rem' }}
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 48 48"
      >
        <path
          fill="#FFC107"
          d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
        />
        <path
          fill="#FF3D00"
          d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
        />
        <path
          fill="#4CAF50"
          d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
        />
        <path
          fill="#1976D2"
          d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
        />
      </svg>
    ),

    buttonStyles: {
      backgroundColor: "#FFFFFF",
      borderColor: "#ebebeb",
      color: "#000000",
    },
  },

  "github.com": {
    icon: (
      <svg
        style={{ height: '1.25rem', width: '1.25rem' }}
        aria-hidden="true"
        viewBox="0 0 24 24"
        fill="#FFFFFF"
      >
        <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
      </svg>
    ),
    buttonStyles: {
      backgroundColor: "#333333",
      borderColor: "#000000",
      color: "#FFFFFF",
    },
  },

  "x.com": {
    icon: (
      <svg style={{ height: '1.50rem', width: '1.50rem' }} fill="#FFF" viewBox="0 0 1668.56 1221.19">
        <g id="layer1" transform="translate(52.390088,-25.058597)">
          <path
            id="path1009"
            d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
    h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"
          />
        </g>
      </svg>
    ),
    buttonStyles: {
      backgroundColor: "#000000",
      borderColor: "#000000",
      color: "#FFFFFF",
    },
  },

  "microsoft.com": {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21 21"
        style={{ height: '1.25rem', width: '1.25rem' }}
      >
        <path fill="#f35325" d="M0 0h10v10H0z" />
        <path fill="#81bc06" d="M11 0h10v10H11z" />
        <path fill="#05a6f0" d="M0 11h10v10H0z" />
        <path fill="#ffba08" d="M11 11h10v10H11z" />
      </svg>
    ),
    buttonStyles: {
      backgroundColor: "#333333",
      borderColor: "#333333",
      color: "#FFFFFF",
    },
  },

  "yahoo.com": {
    icon: (
      <svg style={{ height: '1.25rem', width: '1.25rem' }} viewBox="0 0 3386.34 3010.5">
        <path
          d="M0 732.88h645.84l376.07 962.1 380.96-962.1h628.76l-946.8 2277.62H451.98l259.19-603.53L.02 732.88zm2763.84 768.75h-704.26L2684.65 0l701.69.03-622.5 1501.6zm-519.78 143.72c216.09 0 391.25 175.17 391.25 391.22 0 216.06-175.16 391.23-391.25 391.23-216.06 0-391.19-175.17-391.19-391.23 0-216.05 175.16-391.22 391.19-391.22z"
          fill="#FFFFFF"
        />
      </svg>
    ),
    buttonStyles: {
      backgroundColor: "#720E9E",
      borderColor: "#720E9E",
      color: "#FFFFFF",
    },
  },

  "apple.com": {
    icon: (
      <svg fill="#FFFFFF" style={{ height: '1.25rem', width: '1.25rem' }} viewBox="0 0 22.773 22.773">
        <g>
          <g>
            <path
              d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573
           c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z"
            />
            <path
              d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334
           c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0
           c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019
           c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464
           c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648
           c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z"
            />
          </g>
        </g>
      </svg>
    ),
    buttonStyles: {
      backgroundColor: "#000000",
      borderColor: "#000000",
      color: "#FFFFFF",
    },
  },

  emaillink: {
    icon: (
      <svg style={{ height: '1.25rem', width: '1.25rem' }} viewBox="0 0 256 256" fill="#FFFFFF">
        <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
          <path
            d="M 45 51.815 l 45 -33.87 v -1.967 c 0 -2.03 -1.646 -3.676 -3.676 -3.676 H 3.676 C 1.646 12.302 0 13.948 0 15.978 v 1.967 L 45 51.815 z"
            transform=" matrix(1 0 0 1 0 0) "
          />
          <path
            d="M 47.405 60.019 c -0.712 0.536 -1.559 0.804 -2.405 0.804 s -1.693 -0.268 -2.405 -0.804 L 0 27.958 v 46.064 c 0 2.03 1.646 3.676 3.676 3.676 h 82.648 c 2.03 0 3.676 -1.646 3.676 -3.676 V 27.958 L 47.405 60.019 z"
            transform=" matrix(1 0 0 1 0 0) "
          />
        </g>
      </svg>
    ),
    buttonStyles: {
      backgroundColor: "#ff7883",
      borderColor: "#ff7883",
      color: "#FFFFFF",
    },
  },

  phonenumber: {
    icon: (
      <svg style={{ height: '1.25rem', width: '1.25rem' }} fill="#FFFFFF" viewBox="0 0 24 24">
        <path d="M21.384,17.752a2.108,2.108,0,0,1-.522,3.359,7.543,7.543,0,0,1-5.476.642C10.5,20.523,3.477,13.5,2.247,8.614a7.543,7.543,0,0,1,.642-5.476,2.108,2.108,0,0,1,3.359-.522L8.333,4.7a2.094,2.094,0,0,1,.445,2.328A3.877,3.877,0,0,1,8,8.2c-2.384,2.384,5.417,10.185,7.8,7.8a3.877,3.877,0,0,1,1.173-.781,2.092,2.092,0,0,1,2.328.445Z" />
      </svg>
    ),
    buttonStyles: {
      backgroundColor: "#57bf52",
      borderColor: "#57bf52",
      color: "#FFFFFF",
    },
  },

  "facebook.com": {
    icon: (
      <svg fill="#FFFFFF" style={{ height: '1.25rem', width: '1.25rem' }} viewBox="0 0 95.333 95.333">
        <g>
          <path
            d="M93.333,0H2C0.896,0,0,0.896,0,2v91.332c0,1.104,0.896,2,2,2h48.525V63.477H40.284c-0.885,0-1.602-0.717-1.602-1.602
    V48.961c0-0.885,0.717-1.603,1.602-1.603h10.242v-8.913c0-11.744,7.395-19.332,18.839-19.332l10.188,0.016
    c0.883,0.001,1.6,0.718,1.6,1.602v11.991c0,0.885-0.717,1.602-1.602,1.602l-6.863,0.003c-4.729,0-5.595,1.844-5.595,5.475v7.556
    h11.979c0.425,0,0.832,0.169,1.133,0.47c0.3,0.301,0.469,0.708,0.469,1.133L80.67,61.876c0,0.885-0.717,1.603-1.602,1.603H67.092
    v31.854h26.24c1.104,0,2-0.896,2-2V2C95.333,0.895,94.438,0,93.333,0z"
          />
        </g>
      </svg>
    ),
    buttonStyles: {
      backgroundColor: "#4267B2",
      borderColor: "#ebebeb",
      color: "#FFFFFF",
    },
  },

  default: {
    buttonStyles: {
      backgroundColor: "#FFFFFF",
      color: "#000000",
      borderColor: "#ebebeb",
    },
  },

  anonymous: {
    icon: (
      <svg style={{ height: '1.25rem', width: '1.25rem' }} viewBox="0 0 24 24" fill="#c2c2c2">
        <path d="M16.5 7.063C16.5 10.258 14.57 13 12 13c-2.572 0-4.5-2.742-4.5-5.938C7.5 3.868 9.16 2 12 2s4.5 1.867 4.5 5.063zM4.102 20.142C4.487 20.6 6.145 22 12 22c5.855 0 7.512-1.4 7.898-1.857a.416.416 0 0 0 .09-.317C19.9 18.944 19.106 15 12 15s-7.9 3.944-7.989 4.826a.416.416 0 0 0 .091.317z" />
      </svg>
    ),
    buttonStyles: {
      backgroundColor: "#888888",
      borderColor: "#888888",
      color: "#FFFFFF",
    },
  },
};
