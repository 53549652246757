import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModelData } from '../features/models/modelSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout, Typography, Spin, Card, Button, Row, Col } from 'antd';
import { PaperClipOutlined, PieChartOutlined, MonitorOutlined, UserOutlined, AuditOutlined, SafetyOutlined, FileOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Paragraph } = Typography;

const Obligations = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { modelId } = useParams();
    const userId = useSelector((state) => state.auth.userId);
    const { modelData, modelIsLoading, modelLoadError } = useSelector((state) => state.model);

    useEffect(() => {
        if (userId && modelId && (!modelData || modelData.name !== modelId)) {
            dispatch(fetchModelData({ userId, modelId }));
        }
    }, [userId, modelId, modelData, dispatch]);

    useEffect(() => {
        if (modelData) {
            setIsLoading(modelIsLoading);
            setRequirements(modelData.requirements || {});
            setIsLoading(false);
        }
    }, [modelData, modelIsLoading]);

    const [isLoading, setIsLoading] = useState(true);
    const [requirements, setRequirements] = useState({});

    const InformationSection = ({ title, description, actionPath, disabledButton, buttonText, icon }) => (
        <Card title={<h2 className="text-2xl font-semibold mb-2">{title}</h2>} bordered={true} className='max-w-xl mx-auto mb-4 p-2 bg-white shadow-lg rounded-lg' style={{ margin: '16px 0' }}>
            <Paragraph className='text-gray-700 mb-4'>{description}</Paragraph>
            <div className="flex justify-center">
                { disabledButton ? 
                <Button block disabled type="dashed" className="items-center btn-primary" onClick={() => navigate(actionPath)} icon={icon}>{buttonText}</Button> :
                <Button block type="primary" className="items-center btn-primary" onClick={() => navigate(actionPath)} icon={icon}>{buttonText}</Button>
            }
            </div>
        </Card>
    );

    return (
        <div>
            <Row>
                <Col span={24}>
                {isLoading && <Spin />}
                {!isLoading && modelData && (
                    <>
                        <Row gutter={[16, 16]}>
                        <Col lg={12} md={24} sm={24}>
                            <InformationSection
                                title="Benchmarking"
                                description="Initiate benchmarking procedures to meet regional compliance standards."
                                actionPath={`/models/${modelId}/benchmarking`}
                                buttonText="Start Benchmarking"
                                icon={<PieChartOutlined />}
                            />
                        </Col>
                        <Col lg={12} md={24} sm={24}>
                            <InformationSection
                                title="Monitoring"
                                description="Set up continuous monitoring to track model performance and compliance."
                                actionPath={`/models/${modelId}/monitoring`}
                                buttonText="Setup Monitoring"
                                icon={<MonitorOutlined />}
                            />
                        </Col>
                        </Row>
                        <Row gutter={[16, 16]}> 
                        <Col lg={12} md={24} sm={24}>
                            <InformationSection
                                title="Human Review"
                                description="Engage human oversight for critical decision-making processes."
                                actionPath={`/models/${modelId}/eventreview`}
                                buttonText="Review Events"
                                icon={<UserOutlined />}
                            />
                        </Col>
                        <Col lg={12} md={24} sm={24}>
                            <InformationSection
                                title="Generate Audit"
                                description="Generate comprehensive audit reports for regulatory compliance."
                                actionPath={`/models/${modelId}/audit`}
                                disabledButton={true}
                                buttonText="Generate Audit"
                                icon={<AuditOutlined />}
                            />
                        </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                        <Col lg={12} md={24} sm={24}>
                            <InformationSection
                                title="Screening Policy"
                                description="Review and enforce policies to mitigate risks and ensure safe operations."
                                actionPath={`/models/${modelId}/screeningpolicy`}
                                buttonText="Screening Outputs"
                                icon={<SafetyOutlined />}
                            />
                        </Col>
                        <Col lg={12} md={24} sm={24}>
                            <InformationSection
                                title="Paperwork"
                                description="Manage and submit necessary paperwork for compliance."
                                actionPath={`/models/${modelId}/paperwork`}
                                buttonText="Manage Paperwork"
                                icon={<FileOutlined />}
                            />
                        </Col>
                        </Row>
                    </>
                )}
                </Col>
            </Row>
        </div>
    );
}

export default Obligations;
