import React from 'react';
import { Button, Layout, Typography, Row, Col, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { confirm } = Modal;

const HumanInTheLoop = () => {
  const showStopConfirm = () => {
    confirm({
      title: 'Are you sure you want to stop the AI system?',
      icon: <ExclamationCircleOutlined />,
      content: 'Stopping the AI system will halt all current operations and processes. This action is irreversible through the UI and must be manually restarted by an administrator.',
      okText: 'Stop AI System',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        // Code to stop the AI system goes here
        console.log('AI System Stopped');
      },
      onCancel() {
        console.log('Stop Cancelled');
      },
    });
  };

  return (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
      <Content className='pageContent'>
        <Row justify="center" align="middle">
          <Col span={12} style={{ textAlign: 'center' }}>
            <Title level={2}>Human In The Loop</Title>
            <Paragraph>
              In compliance with regulatory requirements, it's essential to incorporate a "Human In The Loop" system within our AI operations. This ensures that, at any given point, a human can intervene and halt the AI system, should it start behaving unpredictably or in an undesirable manner. The implementation of a "Stop Button" is a critical part of this system, allowing for immediate action to be taken.
            </Paragraph>
            <Button
              type="danger btn-danger"
              shape="circle"
              icon={<ExclamationCircleOutlined />}
              size="xxl"
              onClick={showStopConfirm}
              style={{ fontSize: '40px', height: '200px', width: '200px', margin: '20px 0', fontWeight: 'bold'}}
            >
              Stop
            </Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default HumanInTheLoop;
