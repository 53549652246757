import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ModelsList from '../components/ModelsList';
import { Layout, Typography, Row, Col, Card, Modal, Input, Button } from 'antd';
import NewModelModel from '../components/NewModelModal';
import fetchUserData from '../features/auth/fetchUserData';

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;



const Dashboard = () => {

    const user = useSelector((state) => state.auth.userId);
    const [searchParams, setSearchParams] = useSearchParams();
    const [newModelModal, setNewModelModal] = useState(false);
    const [assessmentId, setAssessmentId] = useState(null);
    const [pdfRequested, setPdfRequested] = useState(false);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const id = searchParams.get('id'); // Get the 'id' query parameter
        if (id) {
            setAssessmentId(id);
            setNewModelModal(true); 
        };
        const pdfRequested = searchParams.get('pdfRequested');
        if (pdfRequested) {
            setPdfRequested(pdfRequested);
        }
    } , [searchParams]);

    // Get user details
    useEffect(() => {
        if (!user) {
            console.log('User not logged in');
        } else {
            fetchUserData(user).then((data) => {
                setUserData(data);
            }).catch((err) => {
                console.error('Error fetching user data:', err);
            });
        }
    }, [user]);

    return (
        <div>
            <div className="pageContent">
                <Title level={3}>Your AI System Compliance Dashboard</Title> 
                <div>
                    { user ? (
                        <Row gutter={[24, 24]}> {/* Row for overall organization */}
                            <Col span={6}>
                                <Card title="User Profile" className="mb-5">
                                    { userData ? (
                                        <>
                                            <Title level={4}>Welcome {userData.displayName}</Title>
                                            <Paragraph>Your main email point of contact is {userData.email}. </Paragraph>
                                            <Paragraph>Your organization is {userData.organisation}.</Paragraph>
                                            <Paragraph>Some aspects of this system are under development or subject to change as we meet new needs of users and regulations. We welcome you to use our concierge support to help you get started. <a href={"mailto:team@scalesafe.ai"}>Contact Us</a> via email anytime, day or night.</Paragraph>
                                        </>
                                    ) : (
                                        <Paragraph>Loading user details... </Paragraph>
                                    )}
                                </Card>

                                {/* Your other dashboard widgets/charts can go in similar 'Card' components */} 
                            </Col>

                            <Col span={18}>
                            <Card title="Current AI Systems" className="mb-5">
                                <ModelsList  setNewModelModal={setNewModelModal}/> {/* Assuming this is a separate component */}
                            </Card>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Paragraph>Loading user details... </Paragraph>
                            <Paragraph>If this persists, check that <a href='/login'>you're logged in.</a></Paragraph>
                        </>
                    )} 

                    <NewModelModel newModelModal={newModelModal} setNewModelModal={setNewModelModal} passedAssessmentId={assessmentId} pdfRequested={pdfRequested}/> 

                </div>
            </div>
        </div>
    );
};

export default Dashboard;
