import React , { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import APIKeys from './pages/APIKeys';
import Dashboard from './pages/Dashboard';
import ModelsList from './components/ModelsList';
import Navigation from './components/Navigation'; // Assuming you'll have a Navigation component
import Welcome from './pages/Welcome'; // Assuming you'll have a Welcome component
import ModelDashboard from './pages/ModelDashboard'; // Assuming you'll have a ModelDashboard component
import Assessment from './pages/Assessment';
import Obligations from './pages/Obligations';
import Benchmarking from './pages/Benchmarking';
import Paperwork from './pages/Paperwork';
import Monitoring from './pages/Monitoring';
import HumanInTheLoop from './pages/HumanInTheLoop';
import EventReviewPage from './pages/EventReview';
import ScreeningPolicyUpdate from './pages/ScreeningPolicyUpdate';
import { Layout } from 'antd';

import { auth } from './firebase'; // Assuming firebase.js is in the src directory
import { useDispatch } from 'react-redux';
import { setUserId } from './features/auth/authSlice'; // Assuming you have a slice for authentication

// TODO: Protect https://stackoverflow.com/questions/66289122/how-to-create-a-protected-route-with-react-router-dom/66289280#66289280  Future work
// TODO: Add a 404 page for unknown routes

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      dispatch(setUserId(user ? user.uid : null));
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/assessment" element={<Assessment />} />
          <Route path="/login" element={<Login />} /> {/* Login and signup and handled in the same page via pop-up*/}
          <Route path="/" element={<Navigation />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/models" element={<ModelsList />} />
            <Route path="/models/:modelId" element={<ModelDashboard />} /> 
            <Route path="/models/:modelId/obligations" element={<Obligations />} />
            <Route path="/models/:modelId/benchmarking" element={<Benchmarking />} />
            <Route path="/models/:modelId/paperwork" element={<Paperwork />} />
            <Route path="/models/:modelId/monitoring" element={<Monitoring />} />
            <Route path="/models/:modelId/api-keys" element={<APIKeys />} /> 
            <Route path="/models/:modelId/eventreview" element={<EventReviewPage />} />
            <Route path="/models/:modelId/screeningpolicy" element={<ScreeningPolicyUpdate />} />
            {/* <Route path="/:modelId/useage" element={<ModelUseage />} />  */}
            {/* <Route path="/:userId/:modelId/edit" element={<ModelEdit/>} />  */}
            {/* <Route path="/thanks" element={<Welcome />} />  */}
          </Route>
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;