import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModelData } from '../features/models/modelSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, query, collection, getDocs } from 'firebase/firestore/lite';
import { Spin, Card, Button, Layout, Typography, Row, Col, Modal} from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// Depending on your theme preference, you can change the style import
import { materialDark as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { requirementToBenchmarkMappings } from '../features/models/modelJotLogic';
import { db } from '../firebase';
import { createApiKey } from './APIKeys';
import { Avatar, List, Space, Badge, Breadcrumb} from 'antd';
import { Tooltip } from 'antd';
import Link from 'antd/es/typography/Link';
import MonitoredEvents from '../components/MonitoredEvents';

const { Content } = Layout;
const { Title, Paragraph, Text} = Typography;

const monitoringCode = `from scalesafe.openai import OpenAIChatMonitor
from openai import OpenAI

client = OpenAI()
monitor = OpenAIChatMonitor() # We create a monitor instance

response = client.chat.completions.create(
  model="gpt-3.5-turbo",
  messages=user_inputs
)

monitor.monitor(response, user_inputs) # This logs for future audits
`;


const Monitoring = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { modelId } = useParams(); 
    const userId = useSelector((state) => state.auth.userId);
    const { modelData, modelIsLoading, modelLoadError } = useSelector((state) => state.model);

    useEffect(() => {
        // Check if modelId is different or modelData is not loaded yet
        if (userId && modelId && (!modelData || modelData.name !== modelId)) {
            console.log('Fetching model data');
            dispatch(fetchModelData({ userId, modelId }));
        }
      }, [userId, modelId, modelData, dispatch]);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (modelLoadError) { 
          setError(modelLoadError);
          return;
        }
        if (modelIsLoading){
          setIsLoading(modelIsLoading);
        } else {
          setIsLoading(false);
          setError(false);
        }
    }, [modelIsLoading, modelLoadError]);

    const [newAPIKey, setNewAPIKey] = useState('');

    const getAPIKey = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const apiKey = await createApiKey(userId, modelId);
            setNewAPIKey(apiKey); 
        } catch (err) {
            setError('Failed to create API key. Please try again.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

  return (
    <div>
    <Breadcrumb items={[{ title: <a href="/dashboard/">models</a> }, { title: <a href={`/models/${modelId}/`}>{modelId}</a>}, { title: <a href={`/models/${modelId}/obligations`}>obligations</a>}, { title: "monitoring"}]} className='breadcrumb' />
    <div class="pageContent max-w-7xl"> 
      <Title level={2}>Continuous Monitoring and Audits</Title>
      <div className='contentBox'>
        {isLoading && <Spin />}
        {error && <Paragraph>{error}</Paragraph>}
        {!isLoading && !error && modelData && (
          <>
            <Row align={'middle'}>
            <Col span={24}>
              <div className="rounded-lg bg-white p-4 max-w-screen-lg mx-auto" style={{ maxWidth: '1200px', margin: 'auto', overflow: 'auto' }}>
                <Col>
                <Title level={4} >For <Text code style={{ fontWeight: 'bold', fontSize: 'large' }}>{modelData.name}</Text></Title>
                <Paragraph>
                  Monitoring is a critical part of maintaining a safe and reliable AI model. ScaleSafe provides a monitoring service that logs all interactions with your model. This allows you to audit your model's performance and ensure it is behaving as expected.
                </Paragraph>
                <Paragraph>
                  This comes in two steps:
                  <li>Sending data to the server (logging)</li>
                  <li>Checking the model and it's outputs are okay.</li>
                </Paragraph>

                <Title level={4}>Wondering how to start monitoring?</Title>
                <Paragraph>
                  To start monitoring your model, you need to install the ScaleSafe package and use an API key that corresponds to this model. The API key will let us know your account and model. You can reuse API keys as much as you want.  </Paragraph>
                <Row justify="center">
                  {newAPIKey ? (
                    <Paragraph>API Key: <Text code style={{ fontWeight: 'bold' }}>{newAPIKey}</Text></Paragraph>
                  ) : (
                    <Button type="primary btn-primary" className="mt-2" onClick={getAPIKey}>
                      Get New API Key
                    </Button>
                  )}
                </Row>
                <Paragraph>Now you start monitoring, checking status, and performing audits. Details can be found on <Link href="https://github.com/ScaleSafe/scalesafe">Github</Link>. Here's an example of how to use the ScaleSafe Python package:</Paragraph>
                <Row justify="center">
                    <SyntaxHighlighter language="python" style={codeStyle} className="rounded-lg mt-2">
                        {monitoringCode}
                    </SyntaxHighlighter>
                </Row>
                </Col>
              </div>

              <div className="rounded-lg bg-white p-4 max-w-screen-lg mx-auto my-4" style={{ maxWidth: '1200px', margin: 'auto', overflow: 'auto' }}>
              <Title level={4}>Recent Events</Title>
              <MonitoredEvents userId={userId} modelId={modelId} nEvents={10}/>
              </div>
            </Col>
            </Row>
          </>
        )}
      </div>
      </div>
    </div>
  );  
};

export default Monitoring;
