// features/models/modelSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore/lite';
import { retryWithExponentialBackoff } from './backoff'

export const fetchModelData = createAsyncThunk(
    'models/fetchModelData',
    async ({ userId, modelId }, { rejectWithValue }) => {
      const fetchOperation = async () => {
        const docRef = doc(db, `/users/${userId}/models/${modelId}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Convert Timestamps to ISO strings (or to JavaScript Date objects)
          if (data.created?.toDate) {
            data.created = data.created.toDate().toISOString(); // Convert to ISO string
          }
          console.log('Model data:', data);
          return data;
        } else {
          throw new Error("Model not found.");
        }
      };
  
      try {
        // Start the operation with an initial delay and number of attempts
        return await retryWithExponentialBackoff(fetchOperation, 3, 1000); // 3 attempts, starting with 1 second delay
      } catch (err) {
        return rejectWithValue(err.message || "Failed to fetch model data. Please try refreshing the page.");
      }
    }
  );
  

const modelSlice = createSlice({
  name: 'model',
  initialState: {
    modelData: null,
    modelIsLoading: false,
    modelLoadError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModelData.pending, (state) => {
        state.modelIsLoading = true;
        state.modelLoadError = null;
      })
      .addCase(fetchModelData.fulfilled, (state, action) => {
        state.modelIsLoading = false;
        state.modelData = action.payload;
        state.modelLoadError = null;
      })
      .addCase(fetchModelData.rejected, (state, action) => {
        state.modelIsLoading = false;
        state.modelLoadError = action.payload;
      });
  },
});

export default modelSlice.reducer;
