export const languages = {
    // Arabic العربية
    "ar": {
        "email": "عنوان البريد الإلكتروني",
        "emailPlaceholder": "you@example.com",
        "password": "كلمة المرور",
        "passwordPlaceholder": "كلمة مرورك",
        "name": "الاسم",
        "namePlaceholder": "اسمك",
        "sendResetLink": "إرسال رابط إعادة التعيين",
        "loginButton": "تسجيل الدخول أو إنشاء حساب",
        "signInWith": "تسجيل الدخول باستخدام",
        "loading": "جار التحميل...",
        "phoneNumber": "رقم الهاتف",
        "emailLink": "رابط البريد الإلكتروني",
        "signInAsGuest": "تسجيل الدخول كضيف",
        "signInWithEmailLink": "تسجيل الدخول باستخدام رابط البريد الإلكتروني",
        "sendEmailLink": "إرسال رابط بريد إلكتروني",
        "cancel": "إلغاء",
        "emailDirty": "يرجى إدخال عنوان بريد إلكتروني صالح.",
        "resetPasswordSent": "تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور إلى",
        "resetPassword": "إعادة تعيين كلمة المرور",
        "nameDirty": "يرجى إدخال اسم صالح",
        "signInLinkSent": "تم إرسال رابط تسجيل الدخول إلى",
        "somethingWentWrong": "حدث خطأ ما. حاول مرة أخرى لاحقًا.",
        "signingYouIn": "جار تسجيل دخولك...",
        "codeSent": "تم إرسال رمز إلى",
        "enterCode": "أدخل الرمز أدناه",
        "verifyIdentity": "ستحتاج إلى التحقق من هويتك للمتابعة",
        "verifyEmail": "ستحتاج إلى التحقق من بريدك الإلكتروني للمتابعة",
        "sendSignInText": "إرسال نص لتسجيل الدخول",
        "countryCode": "رمز الدولة",
        "confirmationTextWillBeSent": "سيتم إرسال نص تأكيد إلى رقم هاتفك المنتهي بـ",
        "finishSigningIn": "إنهاء تسجيل الدخول",
        "sendText": "إرسال نص",
        "sendALinkTo": "إرسال رابط إلى",
        "oneUppercase": "حرف كبير واحد",
        "oneLowercase": "حرف صغير واحد",
        "oneSpecial": "رمز خاص واحد",
        "oneNumber": "رقم واحد",
        "atLeast": "على الأقل",
        "characters": "أحرف",
        "and": "و",
        "andContainAtLeast": "ويحتوي على الأقل",
        "strongPasswordsHave": "الكلمات السرية القوية تتضمن",
        "newPassword": "كلمة المرور الجديدة",
        "newPasswordPlaceholder": "كلمة المرور الجديدة الخاصة بك",
        "emailDirtyNewPassword": "الرجاء إدخال عنوان بريد إلكتروني صالح لإعادة تعيين كلمة المرور",
        "skip": "تخطي",

        "errors": {

            "auth/invalid-login-credentials": "البريد الإلكتروني أو كلمة المرور غير صحيحة",
            "auth/email-already-in-use": "عنوان البريد الإلكتروني هذا مستخدم بالفعل",
            "auth/invalid-email": "يرجى تقديم عنوان بريد إلكتروني صالح",
            "auth/invalid-phone-number": "يرجى تقديم رقم هاتف صالح",
            "auth/invalid-verification-code": "الرمز المقدم غير صالح",
            "auth/popup-closed-by-user": "تم إغلاق النافذة المنبثقة بواسطة المستخدم",
            "auth/cancelled-popup-request": "تم إغلاق النافذة المنبثقة",
            "auth/api-key-not-valid.-please-pass-a-valid-api-key.": "مفتاح واجهة برمجة التطبيقات API لـ Firebase غير صالح.",
            "auth/invalid-credential": "البريد الإلكتروني أو كلمة المرور غير صحيحة"
        },
    },
    // German Deutsch
    "de": {
        "email": "E-Mail-Adresse",
        "emailPlaceholder": "you@example.com",
        "password": "Passwort",
        "passwordPlaceholder": "Ihr Passwort",
        "name": "Name",
        "namePlaceholder": "Ihr Name",
        "sendResetLink": "Reset-Link senden",
        "loginButton": "Einloggen oder Konto erstellen",
        "signInWith": "Anmelden mit",
        "loading": "Laden...",
        "phoneNumber": "Telefonnummer",
        "emailLink": "E-Mail-Link",
        "signInAsGuest": "Als Gast anmelden",
        "signInWithEmailLink": "Mit E-Mail-Link anmelden",
        "sendEmailLink": "E-Mail-Link senden",
        "cancel": "Abbrechen",
        "emailDirty": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
        "resetPasswordSent": "Eine E-Mail zum Zurücksetzen des Passworts wurde gesendet an",
        "resetPassword": "Passwort zurücksetzen",
        "nameDirty": "Bitte geben Sie einen gültigen Namen ein",
        "signInLinkSent": "Ein Anmeldelink wurde gesendet an",
        "somethingWentWrong": "Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.",
        "signingYouIn": "Sie werden angemeldet...",
        "codeSent": "Ein Code wurde gesendet an",
        "enterCode": "Geben Sie den Code unten ein",
        "verifyIdentity": "Sie müssen Ihre Identität verifizieren, um fortzufahren",
        "verifyEmail": "Sie müssen Ihre E-Mail verifizieren, um fortzufahren",
        "sendSignInText": "Ein Anmeldetext senden",
        "countryCode": "Landesvorwahl",
        "confirmationTextWillBeSent": "Ein Bestätigungstext wird an Ihre Telefonnummer gesendet, die endet auf",
        "finishSigningIn": "Anmeldung abschließen",
        "sendText": "Text senden",
        "sendALinkTo": "Senden Sie einen Link an",
        "oneUppercase": "ein Großbuchstabe",
        "oneLowercase": "ein Kleinbuchstabe",
        "oneSpecial": "ein Sonderzeichen",
        "oneNumber": "eine Zahl",
        "atLeast": "mindestens",
        "characters": "Zeichen",
        "and": "und",
        "andContainAtLeast": "und enthält mindestens",
        "strongPasswordsHave": "Starke Passwörter haben",
        "newPassword": "Neues Passwort",
        "newPasswordPlaceholder": "Ihr neues Passwort",
        "emailDirtyNewPassword": "Bitte geben Sie eine gültige E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
        "skip": "Überspringen",
        "errors": {

            "auth/invalid-login-credentials": "Falsche E-Mail oder Passwort",
            "auth/email-already-in-use": "Diese E-Mail-Adresse wird bereits verwendet",
            "auth/invalid-email": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
            "auth/invalid-phone-number": "Bitte geben Sie eine gültige Telefonnummer ein",
            "auth/invalid-verification-code": "Der angegebene Code ist ungültig",
            "auth/popup-closed-by-user": "Das Popup wurde vom Benutzer geschlossen",
            "auth/cancelled-popup-request": "Die Popup-Anfrage wurde abgebrochen",
            "auth/api-key-not-valid.-please-pass-a-valid-api-key.": "Der Firebase-API-Schlüssel ist nicht gültig.",
            "auth/invalid-credential": "Falsche E-Mail oder Passwort"

        },
    },
    // Spanish Español
    "es": {
        "email": "Dirección de correo electrónico",
        "emailPlaceholder": "tu@ejemplo.com",
        "password": "Contraseña",
        "passwordPlaceholder": "tu contraseña",
        "name": "Nombre",
        "namePlaceholder": "tu nombre",
        "sendResetLink": "Enviar enlace de reinicio",
        "loginButton": "Iniciar sesión o crear cuenta",
        "signInWith": "Inicia sesión con",
        "loading": "Cargando...",
        "phoneNumber": "Número de teléfono",
        "emailLink": "Enlace de correo electrónico",
        "signInAsGuest": "Iniciar sesión como invitado",
        "signInWithEmailLink": "Iniciar sesión con enlace de correo electrónico",
        "sendEmailLink": "Enviar enlace de correo electrónico",
        "cancel": "Cancelar",
        "emailDirty": "Por favor, introduce una dirección de correo electrónico válida.",
        "resetPasswordSent": "Se ha enviado un correo electrónico para restablecer la contraseña a",
        "resetPassword": "Restablecer la contraseña",
        "nameDirty": "Introduce un nombre válido",
        "signInLinkSent": "Se ha enviado un enlace para iniciar sesión a",
        "somethingWentWrong": "Algo salió mal. Intente de nuevo más tarde.",
        "signingYouIn": "Iniciando sesión...",
        "codeSent": "Se ha enviado un código a",
        "enterCode": "Ingresa el código a continuación",
        "verifyIdentity": "Deberás verificar tu identidad para continuar",
        "verifyEmail": "Deberás verificar tu correo electrónico para continuar",
        "sendSignInText": "Enviar un texto de inicio de sesión",
        "countryCode": "Código de país",
        "confirmationTextWillBeSent": "Se enviará un texto de confirmación a tu número de teléfono terminado en",
        "finishSigningIn": "Finalizar inicio de sesión",
        "sendText": "Enviar texto",
        "sendALinkTo": "Enviar un enlace a",
        "oneUppercase": "una letra mayúscula",
        "oneLowercase": "una letra minúscula",
        "oneSpecial": "un carácter especial",
        "oneNumber": "un número",
        "atLeast": "al menos",
        "characters": "caracteres",
        "and": "y",
        "andContainAtLeast": "y contener al menos",
        "strongPasswordsHave": "Las contraseñas fuertes tienen",
        "newPassword": "Nueva Contraseña",
        "newPasswordPlaceholder": "tu nueva contraseña",
        "emailDirtyNewPassword": "Por favor, ingresa una dirección de correo válida para restablecer tu contraseña",
        "skip": "Omitir",
        "errors": {
            "auth/invalid-login-credentials": "Correo electrónico o contraseña incorrectos",
            "auth/email-already-in-use": "Esta dirección de correo electrónico ya está en uso",
            "auth/invalid-email": "Por favor, proporciona una dirección de correo válido",
            "auth/invalid-phone-number": "Por favor, proporciona un número de teléfono válido",
            "auth/invalid-verification-code": "El código proporcionado es inválido",
            "auth/popup-closed-by-user": "El popup fue cerrado por el usuario",
            "auth/cancelled-popup-request": "La solicitud de popup fue cancelada",
            "auth/api-key-not-valid.-please-pass-a-valid-api-key.": "La clave API de Firebase no es válida.",
            "auth/invalid-credential": "Correo electrónico o contraseña incorrectos"
        }
    },
    // English
    "en": {
        "email": "Email Address",
        "emailPlaceholder": "you@example.com",
        "password": "Password",
        "passwordPlaceholder": "your password",
        "name": "Name",
        "namePlaceholder": "your name",
        "sendResetLink": "Send Reset Link",
        "loginButton": "Log in or create account",
        "signInWith": "Sign in with",
        "loading": "Loading...",
        "phoneNumber": "Phone Number",
        "emailLink": "Email Link",
        "signInAsGuest": "Sign in as Guest",
        "signInWithEmailLink": "Sign in with Email Link",
        "sendEmailLink": "Send Email Link",
        "cancel": "Cancel",
        "emailDirty": "Please enter a valid email address.",
        "resetPasswordSent": "A reset-password email has been sent to",
        "resetPassword": "Reset Password",
        "nameDirty": "Please enter a valid name",
        "signInLinkSent": "A sign in link has been sent to",
        "somethingWentWrong": "Something went wrong. Try again later.",
        "signingYouIn": "Signing you in...",
        "codeSent": "A code has been sent to",
        "enterCode": "Enter code below",
        "verifyIdentity": "You'll need to verify your identity to continue",
        "verifyEmail": "You'll need to verify your email to continue",
        "sendSignInText": "Send a sign in text",
        "countryCode": "Country Code",
        "confirmationTextWillBeSent": "A confirmation text will be sent to your phone number ending in",
        "finishSigningIn": "Finish signing in",
        "sendText": "Send text",
        "sendALinkTo": "Send a link to",
        "oneUppercase": "one uppercase letter",
        "oneLowercase": "one lowercase letter",
        "oneSpecial": "one special character",
        "oneNumber": "one number",
        "atLeast": "at least",
        "characters": "characters",
        "and": "and",
        "andContainAtLeast": "and contain at least",
        "strongPasswordsHave": "Strong passwords have",
        "newPassword": "New Password",
        "newPasswordPlaceholder": "your new password",
        "emailDirtyNewPassword": "Please enter a valid email address to reset your password",
        "skip": "Skip",

        "errors": {

            "auth/invalid-login-credentials": "Incorrect email or password",
            "auth/email-already-in-use": "This email address is already in use",
            "auth/invalid-email": "Please provide a valid email address",
            "auth/invalid-phone-number": "Please provide a valid phone number",
            "auth/invalid-verification-code": "The code provided is invalid",
            "auth/popup-closed-by-user": "The popup was closed",
            "auth/cancelled-popup-request": "The popup was closed",
            "auth/api-key-not-valid.-please-pass-a-valid-api-key.": "The Firebase API key is not valid.",
            "auth/invalid-credential": "Incorrect email or password"

        },


    },
    // French Français
    "fr": {
        "email": "Adresse électronique",
        "emailPlaceholder": "vous@exemple.com",
        "password": "Mot de passe",
        "passwordPlaceholder": "votre mot de passe",
        "name": "Nom",
        "namePlaceholder": "votre nom",
        "sendResetLink": "Envoyer le lien de réinitialisation",
        "loginButton": "Se connecter ou créer un compte",
        "signInWith": "Se connecter avec",
        "loading": "Chargement...",
        "phoneNumber": "Numéro de téléphone",
        "emailLink": "Lien électronique",
        "signInAsGuest": "Se connecter en tant qu'invité",
        "signInWithEmailLink": "Se connecter avec le lien électronique",
        "sendEmailLink": "Envoyer le lien électronique",
        "cancel": "Annuler",
        "emailDirty": "Veuillez saisir une adresse électronique valide.",
        "resetPasswordSent": "Un courriel de réinitialisation de mot de passe a été envoyé à",
        "resetPassword": "Réinitialiser le mot de passe",
        "nameDirty": "Veuillez entrer un nom valide",
        "signInLinkSent": "Un lien de connexion a été envoyé à",
        "somethingWentWrong": "Un problème est survenu. Essayez de nouveau plus tard.",
        "signingYouIn": "Connexion en cours...",
        "codeSent": "Un code a été envoyé à",
        "enterCode": "Entrez le code ci-dessous",
        "verifyIdentity": "Vous devez vérifier votre identité pour continuer",
        "verifyEmail": "Vous devez vérifier votre adresse électronique pour continuer",
        "sendSignInText": "Envoyer un texto de connexion",
        "countryCode": "Code du pays",
        "confirmationTextWillBeSent": "Un texto de confirmation sera envoyé à votre numéro de téléphone se terminant par",
        "finishSigningIn": "Terminer la connexion",
        "sendText": "Envoyer un texto",
        "sendALinkTo": "Envoyer un lien à",
        "oneUppercase": "une lettre majuscule",
        "oneLowercase": "une lettre minuscule",
        "oneSpecial": "un caractère spécial",
        "oneNumber": "un chiffre",
        "atLeast": "au moins",
        "characters": "caractères",
        "and": "et",
        "andContainAtLeast": "et contient au moins",
        "strongPasswordsHave": "Les mots de passe forts comportent",
        "newPassword": "Nouveau Mot de Passe",
        "newPasswordPlaceholder": "votre nouveau mot de passe",
        "emailDirtyNewPassword": "Veuillez entrer une adresse e-mail valide pour réinitialiser votre mot de passe",
        "skip": "Passer",
        "errors": {

            "auth/invalid-login-credentials": "Courriel ou mot de passe incorrect",
            "auth/email-already-in-use": "Cette adresse électronique est déjà utilisée",
            "auth/invalid-email": "Veuillez fournir une adresse électronique valide",
            "auth/invalid-phone-number": "Veuillez fournir un numéro de téléphone valide",
            "auth/invalid-verification-code": "Le code fourni est invalide",
            "auth/popup-closed-by-user": "La fenêtre pop-up a été fermée",
            "auth/cancelled-popup-request": "La fenêtre pop-up a été fermée",
            "auth/api-key-not-valid.-please-pass-a-valid-api-key.": "La clé API Firebase n'est pas valide.",
            "auth/invalid-credential": "Courriel ou mot de passe incorrect"

        },


    },
    // Hindi हिन्दी
    "hi": {
        "email": "ईमेल पता",
        "emailPlaceholder": "you@example.com",
        "password": "पासवर्ड",
        "passwordPlaceholder": "आपका पासवर्ड",
        "name": "नाम",
        "namePlaceholder": "आपका नाम",
        "sendResetLink": "रीसेट लिंक भेजें",
        "loginButton": "लॉग इन करें या खाता बनाएँ",
        "signInWith": "इसके साथ साइन इन करें",
        "loading": "लोड हो रहा है...",
        "phoneNumber": "फोन नंबर",
        "emailLink": "ईमेल लिंक",
        "signInAsGuest": "अतिथि के रूप में साइन इन करें",
        "signInWithEmailLink": "ईमेल लिंक के साथ साइन इन करें",
        "sendEmailLink": "ईमेल लिंक भेजें",
        "cancel": "रद्द करें",
        "emailDirty": "कृपया वैध ईमेल पता दर्ज करें।",
        "resetPasswordSent": "पासवर्ड रीसेट ईमेल भेज दिया गया है",
        "resetPassword": "पासवर्ड रीसेट करें",
        "nameDirty": "कृपया वैध नाम दर्ज करें",
        "signInLinkSent": "साइन इन लिंक भेजा गया है",
        "somethingWentWrong": "कुछ गलत हो गया। बाद में पुन: प्रयास करें।",
        "signingYouIn": "आपको साइन इन कर रहे हैं...",
        "codeSent": "कोड भेजा गया है",
        "enterCode": "नीचे कोड दर्ज करें",
        "verifyIdentity": "जारी रखने के लिए आपको अपनी पहचान सत्यापित करनी होगी",
        "verifyEmail": "जारी रखने के लिए आपको अपना ईमेल सत्यापित करना होगा",
        "sendSignInText": "साइन इन टेक्स्ट भेजें",
        "countryCode": "देश कोड",
        "confirmationTextWillBeSent": "आपके फोन नंबर पर एक पुष्टिकरण टेक्स्ट भेजा जाएगा जो समाप्त होता है",
        "finishSigningIn": "साइन इन समाप्त करें",
        "sendText": "टेक्स्ट भेजें",
        "sendALinkTo": "इसे लिंक भेजें",
        "oneUppercase": "एक अपरकेस अक्षर",
        "oneLowercase": "एक लोअरकेस अक्षर",
        "oneSpecial": "एक विशेष चरित्र",
        "oneNumber": "एक नंबर",
        "atLeast": "कम से कम",
        "characters": "अक्षर",
        "and": "और",
        "andContainAtLeast": "और कम से कम होना चाहिए",
        "strongPasswordsHave": "मजबूत पासवर्ड में होते हैं",
        "newPassword": "नया पासवर्ड",
        "newPasswordPlaceholder": "आपका नया पासवर्ड",
        "emailDirtyNewPassword": "कृपया अपना पासवर्ड रीसेट करने के लिए एक मान्य ईमेल पता दर्ज करें",
        "skip": "छोड़ें",
        "errors": {

            "auth/invalid-login-credentials": "ईमेल या पासवर्ड गलत है",
            "auth/email-already-in-use": "यह ईमेल पता पहले से ही इस्तेमाल में है",
            "auth/invalid-email": "कृपया वैध ईमेल पता प्रदान करें",
            "auth/invalid-phone-number": "कृपया एक वैध फोन नंबर प्रदान करें",
            "auth/invalid-verification-code": "प्रदान किया गया कोड अवैध है",
            "auth/popup-closed-by-user": "पॉपअप उपयोगकर्ता द्वारा बंद किया गया था",
            "auth/cancelled-popup-request": "पॉपअप अनुरोध रद्द कर दिया गया था",
            "auth/api-key-not-valid.-please-pass-a-valid-api-key.": "Firebase API कुंजी वैध नहीं है।",
            "auth/invalid-credential": "ईमेल या पासवर्ड गलत है"
        },
    },
    // Portuguese Português
    "pt": {
        "email": "Endereço de Email",
        "emailPlaceholder": "você@exemplo.com",
        "password": "Senha",
        "passwordPlaceholder": "sua senha",
        "name": "Nome",
        "namePlaceholder": "seu nome",
        "sendResetLink": "Enviar Link de Redefinição",
        "loginButton": "Entrar ou criar conta",
        "signInWith": "Entrar com",
        "loading": "Carregando...",
        "phoneNumber": "Número de Telefone",
        "emailLink": "Link de Email",
        "signInAsGuest": "Entrar como Convidado",
        "signInWithEmailLink": "Entrar com Link de Email",
        "sendEmailLink": "Enviar Link de Email",
        "cancel": "Cancelar",
        "emailDirty": "Por favor, insira um endereço de email válido.",
        "resetPasswordSent": "Um email de redefinição de senha foi enviado para",
        "resetPassword": "Redefinir Senha",
        "nameDirty": "Por favor, insira um nome válido",
        "signInLinkSent": "Um link de entrada foi enviado para",
        "somethingWentWrong": "Algo deu errado. Tente novamente mais tarde.",
        "signingYouIn": "Entrando...",
        "codeSent": "Um código foi enviado para",
        "enterCode": "Insira o código abaixo",
        "verifyIdentity": "Você precisará verificar sua identidade para continuar",
        "verifyEmail": "Você precisará verificar seu email para continuar",
        "sendSignInText": "Enviar um texto de entrada",
        "countryCode": "Código do País",
        "confirmationTextWillBeSent": "Um texto de confirmação será enviado para o seu número de telefone terminando em",
        "finishSigningIn": "Finalizar entrada",
        "sendText": "Enviar texto",
        "sendALinkTo": "Enviar um link para",
        "oneUppercase": "uma letra maiúscula",
        "oneLowercase": "uma letra minúscula",
        "oneSpecial": "um caractere especial",
        "oneNumber": "um número",
        "atLeast": "pelo menos",
        "characters": "caracteres",
        "and": "e",
        "andContainAtLeast": "e conter pelo menos",
        "strongPasswordsHave": "Senhas fortes têm",
        "newPassword": "Nova Senha",
        "newPasswordPlaceholder": "sua nova senha",
        "emailDirtyNewPassword": "Por favor, insira um endereço de email válido para redefinir sua senha",
        "skip": "Pular",
        "errors": {

            "auth/invalid-login-credentials": "Email ou senha incorretos",
            "auth/email-already-in-use": "Este endereço de email já está em uso",
            "auth/invalid-email": "Por favor, forneça um endereço de email válido",
            "auth/invalid-phone-number": "Por favor, forneça um número de telefone válido",
            "auth/invalid-verification-code": "O código fornecido é inválido",
            "auth/popup-closed-by-user": "O popup foi fechado pelo usuário",
            "auth/cancelled-popup-request": "O pedido de popup foi cancelado",
            "auth/api-key-not-valid.-please-pass-a-valid-api-key.": "A chave de API do Firebase não é válida.",
            "auth/invalid-credential": "Email ou senha incorretos"

        },
    },
    // Russian Русский
    "ru": {
        "email": "Адрес электронной почты",
        "somethingWentWrong": "Что-то пошло не так. Попробуйте позже.",
        "emailPlaceholder": "you@example.com",
        "password": "Пароль",
        "passwordPlaceholder": "ваш пароль",
        "name": "Имя",
        "namePlaceholder": "ваше имя",
        "sendResetLink": "Отправить ссылку для сброса",
        "loginButton": "Войти или создать аккаунт",
        "signInWith": "Войти с помощью",
        "loading": "Загрузка...",
        "phoneNumber": "Номер телефона",
        "emailLink": "Ссылка электронной почты",
        "signInAsGuest": "Войти как гость",
        "signInWithEmailLink": "Войти с помощью ссылки на электронную почту",
        "sendEmailLink": "Отправить ссылку на электронную почту",
        "cancel": "Отмена",
        "emailDirty": "Пожалуйста, введите действительный адрес электронной почты.",
        "resetPasswordSent": "Ссылка для сброса пароля была отправлена на",
        "resetPassword": "Сбросить пароль",
        "nameDirty": "Пожалуйста, введите действительное имя",
        "signInLinkSent": "Ссылка для входа отправлена на",
        "signingYouIn": "Входим...",
        "codeSent": "Код был отправлен на",
        "enterCode": "Введите код ниже",
        "verifyIdentity": "Вам нужно подтвердить свою личность, чтобы продолжить",
        "verifyEmail": "Вам нужно подтвердить свою электронную почту, чтобы продолжить",
        "sendSignInText": "Отправить текст для входа",
        "countryCode": "Код страны",
        "confirmationTextWillBeSent": "Текст подтверждения будет отправлен на ваш номер телефона, оканчивающийся на",
        "finishSigningIn": "Завершить вход",
        "sendText": "Отправить текст",
        "sendALinkTo": "Отправить ссылку на",
        "oneUppercase": "одна прописная буква",
        "oneLowercase": "одна строчная буква",
        "oneSpecial": "один специальный символ",
        "oneNumber": "одна цифра",
        "atLeast": "по крайней мере",
        "characters": "символов",
        "and": "и",
        "andContainAtLeast": "и содержит как минимум",
        "strongPasswordsHave": "Сильные пароли имеют",
        "newPassword": "Новый пароль",
        "newPasswordPlaceholder": "ваш новый пароль",
        "emailDirtyNewPassword": "Пожалуйста, введите действительный адрес электронной почты чтобы сбросить пароль",
        "skip": "Пропустить",

        "errors": {

            "auth/invalid-login-credentials": "Неверный адрес электронной почты или пароль",
            "auth/email-already-in-use": "Этот адрес электронной почты уже используется",
            "auth/invalid-email": "Пожалуйста, укажите действительный адрес электронной почты",
            "auth/invalid-phone-number": "Пожалуйста, укажите действительный номер телефона",
            "auth/invalid-verification-code": "Указанный код недействителен",
            "auth/popup-closed-by-user": "Всплывающее окно было закрыто пользователем",
            "auth/cancelled-popup-request": "Запрос во всплывающем окне был отменен",
            "auth/api-key-not-valid.-please-pass-a-valid-api-key.": "Ключ API Firebase недействителен.",
            "auth/invalid-credential": "Неверный адрес электронной почты или пароль"
        },
    },
    // Chinese 中文
    "zh": {
        "email": "电子邮件地址",
        "emailPlaceholder": "you@example.com",
        "password": "密码",
        "passwordPlaceholder": "您的密码",
        "name": "名称",
        "namePlaceholder": "您的姓名",
        "sendResetLink": "发送重置链接",
        "loginButton": "登录或创建账户",
        "signInWith": "使用以下方式登录",
        "loading": "加载中...",
        "phoneNumber": "电话号码",
        "emailLink": "电子邮件链接",
        "signInAsGuest": "以访客身份登录",
        "signInWithEmailLink": "通过电子邮件链接登录",
        "sendEmailLink": "发送电子邮件链接",
        "cancel": "取消",
        "emailDirty": "请输入有效的电子邮件地址。",
        "resetPasswordSent": "重置密码的电子邮件已发送至",
        "resetPassword": "重置密码",
        "nameDirty": "请输入有效的名称",
        "signInLinkSent": "登陆链接已发送至",
        "somethingWentWrong": "出错了。请稍后再试。",
        "signingYouIn": "正在登录...",
        "codeSent": "代码已发送至",
        "enterCode": "在下面输入代码",
        "verifyIdentity": "您需要验证您的身份以继续",
        "verifyEmail": "您需要验证您的电子邮件以继续",
        "sendSignInText": "发送登录短信",
        "countryCode": "国家代码",
        "confirmationTextWillBeSent": "确认短信将发送到您的电话号码，结尾为",
        "finishSigningIn": "完成登录",
        "sendText": "发送短信",
        "sendALinkTo": "发送链接到",
        "oneUppercase": "一个大写字母",
        "oneLowercase": "一个小写字母",
        "oneSpecial": "一个特殊字符",
        "oneNumber": "一个数字",
        "atLeast": "至少",
        "characters": "个字符",
        "and": "和",
        "andContainAtLeast": "并且包含至少",
        "strongPasswordsHave": "强密码应该具有",
        "newPassword": "新密码",
        "newPasswordPlaceholder": "您的新密码",
        "emailDirtyNewPassword": "请输入有效的电子邮件地址以重置您的密码",
        "skip": "跳过",

        "errors": {

            "auth/invalid-login-credentials": "电子邮件或密码错误",
            "auth/email-already-in-use": "该电子邮件地址已被使用",
            "auth/invalid-email": "请提供有效的电子邮件地址",
            "auth/invalid-phone-number": "请提供有效的电话号码",
            "auth/invalid-verification-code": "提供的代码无效",
            "auth/popup-closed-by-user": "弹出窗口已被用户关闭",
            "auth/cancelled-popup-request": "弹出窗口请求已取消",
            "auth/api-key-not-valid.-please-pass-a-valid-api-key.": "Firebase API密钥无效。",
            "auth/invalid-credential": "电子邮件或密码错误"

        },


    },
}

export const translate = (key, language, customTextMap = null) => {
    console.log(language)
    // Check if customTextMap is not null and key exists, otherwise proceed
    return customTextMap?.[key]
        ?? languages[language]?.[key]
        ?? languages["en"][key]
};

export const translateError = (key, language, customTextMap = null) => {
    console.log(key)
    // Use optional chaining and nullish coalescing to gracefully handle null or undefined values
    let rest = customTextMap?.[key]
        ?? languages[language]?.errors?.[key]
        ?? languages[language]?.["somethingWentWrong"]
        ?? languages["en"].errors[key]
        ?? languages["en"]?.["somethingWentWrong"];

    console.log(rest);
    return rest
};