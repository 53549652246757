import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore/lite'; 
import { db } from '../firebase';
import { List, Typography, Spin, Collapse } from 'antd';
import { fetchModelEvents } from '../features/models/eventSlice';

const { Paragraph } = Typography;
const { Panel } = Collapse;

const MonitoredEvents = ({ userId, modelId, nEvents }) => {
    const [modelEvents, setModelEvents] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(false);
    const [eventsError, setEventsError] = useState(null);
    
    useEffect(() => {
        if (!userId || !modelId) return;
        setEventsLoading(true);
        const fetchEvents = async () => {
            try {
                console.log('Fetching model events for model dashboard.', userId, modelId, nEvents);
                const data = await fetchModelEvents(db, userId, modelId, nEvents);
                setModelEvents(data);
            } catch (err) {
                setEventsError("Failed to fetch model events. Please try refreshing the page.");
            } finally {
                setEventsLoading(false);                
            }
        };
        fetchEvents();
    }, [userId, modelId, nEvents]);

    useEffect(() => {
        console.log('Fetching model events completed', modelEvents);
    }, [modelEvents]);

    const generatePreviewText = text => {
        return text && text.length > 30 ? text.substring(0, 30) + '...' : text;
    };

    const renderData = data => {
        if (typeof data === 'string') {
            return data;
        } else if (typeof data === 'object') {
            return JSON.stringify(data, null, 2);
        }
        return 'Invalid data format';
    };


    return (
        <Spin spinning={eventsLoading}>
            {eventsError ? (
                <Paragraph type="danger">{eventsError}</Paragraph>
            ) : (
                modelEvents.length === 0 ? (
                    <Paragraph>No events for this model yet.</Paragraph>
                ) : (
                    <>
                    <Paragraph> Showing most recent {Math.max(modelEvents.length, nEvents)} events.</Paragraph>
                    <Collapse accordion>
                        {modelEvents.map(item => (
                            <Panel header={`API Call - ${new Date(item.model_start_time * 1000).toLocaleString()}`} key={item.id}>
                                <List.Item>
                                    <List.Item.Meta
                                        title={`Event Details for ${item.model_version} at ${item.model_start_time}`}
                                        description={
                                            <>
                                                <Paragraph>
                                                    <strong>Model Inputs:</strong> {renderData(item.model_inputs)}
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>Model Outputs:</strong> {renderData(item.model_outputs)}
                                                </Paragraph>
                                            </>
                                        }
                                    />
                                </List.Item>
                            </Panel>
                        ))}
                    </Collapse>
                    </>
                )
            )}
        </Spin>
    );
};

export default MonitoredEvents;
