import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDocs, collection, setDoc, updateDoc } from 'firebase/firestore/lite'; 
import { v4 as uuidv4 } from 'uuid';
import { Table, Button, Typography, Space, Spin, Input, Popconfirm, Modal, Breadcrumb} from 'antd';
import { DeleteOutlined } from '@ant-design/icons'; 
import { Layout} from 'antd';

const { Content, Header} = Layout;
const { Paragraph, Text } = Typography;

export const createApiKey = async (userId, modelId) => {

    const rawUuid = uuidv4();
    const newKey = `sk-${rawUuid.replace(/-/g, '').slice(0, 30)}-${userId}`;

    await setDoc(doc(db, `/users/${userId}/keys/`, newKey), {
      key: newKey,
      created: new Date(),
      lastUsed: null,
      calls: 0,
      isLive: true,
      modelId: modelId
    });

    return newKey;
};


const APIKeys = () => {
    const [apiKeys, setApiKeys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [visible, setVisible] = useState(false);
    const [currentKey, setCurrentKey] = useState('');

    const { modelId } = useParams();
    const userId = useSelector((state) => state.auth.userId);

    useEffect(() => {
      const fetchApiKeys = async () => {
        setIsLoading(true);
        setError(null);

        try {
          const collectionRef = collection(db, `/users/${userId}/keys/`);
          const querySnapshot = await getDocs(collectionRef);

          if (!querySnapshot.empty) {
            const keysData = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              created: doc.data().created?.toDate().toLocaleString() ?? 'N/A', // Ensure you format the date as needed
                lastUsed: doc.data().lastUsed?.toDate().toLocaleString() ?? 'Never',
              })).filter(key => key.isLive && key.modelId === modelId); // Only include keys where isLive is true and modelId matches
            setApiKeys(keysData);
          }

        } catch (err) {
          setError('Failed to fetch API keys. Please try refreshing the page.');
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      };

      fetchApiKeys();
    }, [userId, modelId]);

    
    const callCreateApiKey = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const newKey = await createApiKey(userId, modelId);
          setApiKeys(prevKeys => [
            ...prevKeys,
            {
              id: newKey,
              key: newKey,
              created: new Date().toLocaleString(), 
              lastUsed: 'Never',
              calls: 0
            }
          ]);
        } catch (err) {
          setError('Failed to create a new API key. Please try again.');
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      };

    const deleteKey = async (id) => {
      setIsLoading(true);
      setError(null);

      try {
        const keyRef = doc(db, `/users/${userId}/models/${modelId}/keys`, id);
        await updateDoc(keyRef, {
          isLive: false,
        });

        setApiKeys(prevKeys => prevKeys.filter(key => key.id !== id));
      } catch (err) {
        setError('Failed to delete the API key. Please try again.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    const handleReveal = (key) => {
      setCurrentKey(key); // Store the selected key
      setVisible(true);
    };

    const handleClose = () => {
      setVisible(false);
    };

    const confirmDelete = (id) => { 
      Popconfirm({
        title: 'Are you sure you want to delete this API key?',
        onConfirm: () => deleteKey(id), // Call deleteKey if confirmed
        okText: 'Yes',
        cancelText: 'No'
      });
    };


    const columns = [
      { 
        title: 'API Key', 
        dataIndex: 'key', 
        width: '40%',
        render: (key) => key.substring(0, 10) + '...'
      },
      {
        title: 'Actions',
        key: 'action',
        width: 180,
        render: (record) => (
          <Space size="middle">
            <Button type="primary btn-primary" size="small" onClick={() => handleReveal(record.key)}>Reveal</Button>
            <Button type="danger" ghost size="small" icon={<DeleteOutlined />} onClick={() => confirmDelete(record.id)} />
          </Space>
        ),
      },
      { title: 'Created', dataIndex: 'created', width: '20%' },
      { title: 'Last Used', dataIndex: 'lastUsed', width: '20%' },
      { title: 'Calls', dataIndex: 'calls', width: 80 },
    ];
  

    if (isLoading) {
        return <p>Loading...</p>;
    }
    if (error) {
      return <p>{error}</p>
    }
    return (
      <div>
        <Breadcrumb items={[{ title: <a href="/dashboard/">models</a> }, { title: <a href={`/models/${modelId}/`}>{modelId}</a>}, { title: "API Keys"}]} className='breadcrumb' />
        <div className="pageContent">
          <Typography.Title level={3}>API Keys</Typography.Title>
        
          <div className="contentBox">
            <Paragraph>API Key (or application secrets) are used to example access between ScaleSafe's operations and your AI system. These keys are unique to each AI system and currently correspond to the model:  <Text code style={{ fontWeight: 'bold' }}>{modelId}</Text></Paragraph>

            <Paragraph>These keys behave a lot like OpenAI API keys or other common tools, with this key full encoding your secret, the access control, and your account information.</Paragraph>

            <Paragraph>Each key has a unique identifier, creation date, and usage statistics. You can create a new key, delete an existing key, or reveal the full key for use in your application.</Paragraph>
      
            <Spin spinning={isLoading}>
              <Table columns={columns} dataSource={apiKeys} rowKey="id" />
              <div style={{ marginTop: '10px' }}>
                <Button type="primary btn-primary" onClick={callCreateApiKey}>Create new secret key</Button>
              </div>
            </Spin>
            <Modal
              title="API Key"
              open={visible}
              onCancel={handleClose}
              footer={null} 
              // width={400} 
            >
              <Input value={currentKey} readOnly />
            </Modal>
          </div>
        </div>
      </div>
    );
  };
  
  export default APIKeys;